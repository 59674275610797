export default [
  // GESTION
  {
    path: "/realestate/manage",
    name: "realestate-manage",
    component: () => import("@/views/api-manage/ApiManage.vue"),
    meta: {
      resource: "APIManagementPages",
      pageTitle: "Gestion - API Realestate",
      contentClass: "itemgrid-application",
      navActiveLink: "realestate-manage",
    },
  },
  // DOCUMENTATION
  {
    path: "/realestate/redoc",
    name: "realestate-redoc",
    component: () => import("@/views/redoc/Redoc.vue"),
    meta: {
      resource: "RealestatePages",
      pageTitle: "Realestate Redoc",
    },
  },
  // DEMO
  {
    path: "/realestate/demo/searcher",
    name: "realestate-searcher",
    component: () => import("@/views/realestate/demo/searcher/Searcher.vue"),
    meta: {
      resource: "RealestatePages",
      pageTitle: "Recherche",
      contentRenderer: "sidebar-left-detached",
      contentClass: "itemgrid-application",
    },
  },
  {
    path: "/realestate/demo/property",
    name: "realestate-property",
    component: () =>
      import("@/views/realestate/demo/property-card/PropertyCard.vue"),
    meta: {
      resource: "RealestatePages",
      pageTitle: "Annonce",
      navActiveLink: "realestate-searcher",
    },
  },
];
