export default [
  {
    path: "/ads/sales",
    name: "ads-sales",
    component: () => import("@/views/ads/sales/AdsListSales.vue"),
    meta: {
      resource: "AdsPages",
      pageTitle: "Ventes",
    },
  },
  {
    path: "/ads/rentals",
    name: "ads-rentals",
    component: () => import("@/views/ads/rentals/AdsListRentals.vue"),
    meta: {
      resource: "AdsPages",
      pageTitle: "Locations",
    },
  },
  {
    path: "/ads/programs",
    name: "ads-programs",
    component: () => import("@/views/ads/programs/AdsListPrograms.vue"),
    meta: {
      resource: "AdsPages",
      pageTitle: "Programmes",
    },
  },
];
