import Vue from "vue";
import * as Api from "../../axios-generated/backoffice";
import axios from "@axios";
import router from "../../router/index";

const getCompanyAPI = () => {
  return new Api.CompaniesApi(
    null,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL
      : "",
    axios
  );
};

const getInvoiceAccountsAPI = () => {
  return new Api.InvoiceAccountsApi(
    null,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL
      : "",
    axios
  );
};

const getDefaultState = () => {
  return {
    invoiceAccounts: [],
    selectedInvoiceAccount: {},
  };
};

export default {
  namespaced: true,
  state: {
    invoiceAccounts: [],
    selectedInvoiceAccount: {},
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    pushInvoiceAccount(state, val) {
      state.invoiceAccounts.push(val);
    },
    setInvoiceAccounts(state, val) {
      if (Object.keys(state.selectedInvoiceAccount).length === 0) {
        state.selectedInvoiceAccount = val[0];
      }
      state.invoiceAccounts = val;
    },
    setSelectedInvoiceAccount(state, val) {
      state.selectedInvoiceAccount = val;
      // change route to dashboard
      router.push("/");
    },
  },
  actions: {
    async resetModuleState({ commit }) {
      commit("resetState");
    },
    async findInvoiceAccountsCurrentUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        getCompanyAPI()
          .findInvoiceAccountsByCompanyID(
            params.companyId,
            params.sortBy,
            params.perPage,
            params.page,
            params.sortDesc
          )
          .then((response) => {
            commit("setInvoiceAccounts", response.data.invoice_accounts);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async findInvoiceAccountsByCompanyID(ctx, params) {
      return new Promise((resolve, reject) => {
        getCompanyAPI()
          .findInvoiceAccountsByCompanyID(
            params.companyId,
            params.sortBy,
            params.perPage,
            params.page,
            params.sortDesc
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async createInvoiceAccount({ commit }, newInvoiceAccount) {
      return new Promise((resolve, reject) => {
        getInvoiceAccountsAPI()
          .createInvoiceAccount(newInvoiceAccount)
          .then((response) => {
            commit("pushInvoiceAccount", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async findSubscriptionsByInvoiceAccountID(ctx, invoiceAccountID) {
      return new Promise((resolve, reject) => {
        getInvoiceAccountsAPI()
          .findSubscriptionsByInvoiceAccountID(invoiceAccountID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
