import Vue from "vue";
import VueRouter from "vue-router";

// Security
import { canNavigate } from "@/libs/acl/routeProtection";

// Routes
import auths from "./routes/auths";
import boards from "./routes/boards";
import users from "./routes/users";
import customers from "./routes/customers";
import products from "./routes/products";
import flows from "./routes/flows";
import realestate from "./routes/realestate";
import estimate from "./routes/estimate";
import situation from "./routes/situation";
import decimmo from "./routes/decimmo";
import ads from "./routes/ads";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...auths,
    ...boards,
    ...users,
    ...customers,
    ...products,
    ...flows,
    ...realestate,
    ...estimate,
    ...situation,
    ...decimmo,
    ...ads,
    { path: "/", redirect: { name: "board-public" } },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        resource: "ErrorPages",
      },
    },
    {
      path: "/not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/error/NotAuthorized.vue"),
      meta: {
        layout: "full",
        resource: "ErrorPages",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!Vue.prototype.$auth.currentUser;

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  return next();
});

export default router;
