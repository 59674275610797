import Vue from "vue";

import jwtDefaultConfig from "./jwtDefaultConfig";
import store from "@/store";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  constructor(axiosIns) {
    this.axiosIns = axiosIns;

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Ignore if goecoding
        if (config.url.includes("api-adresse.data.gouv.fr")) {
          return config;
        }
        // Check Realestate Api Call
        if (
          config.url.includes(
            Vue.prototype.$config.VUE_APP_REALESTATE_API_BASE_URL
          )
        ) {
          config.headers[
            "Authorization"
          ] = `Bearer ${Vue.prototype.$config.VUE_APP_REALESTATE_API_KEY}`;
          return config;
        }

        // Check Estimate Api Call
        if (
          config.url.includes(
            Vue.prototype.$config.VUE_APP_ESTIMATE_API_BASE_URL
          )
        ) {
          config.headers[
            "Authorization"
          ] = `Bearer ${Vue.prototype.$config.VUE_APP_ESTIMATE_API_KEY}`;
          return config;
        }

        // Check Situation Api Call
        if (
          config.url.includes(
            Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL
          )
        ) {
          config.headers[
            "Authorization"
          ] = `Bearer ${Vue.prototype.$config.VUE_APP_SITUATION_API_KEY}`;
          return config;
        }

        // Get token from localStorage
        return Vue.prototype.$auth.currentUser.getIdToken().then((idToken) => {
          // Send token to your backend via HTTPS
          if (idToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${idToken}`;
          }
          return config;
        });
      },
      (error) => Promise.reject(error)
    );

    // Response Interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (
          response &&
          response.status === 401 &&
          (response.config.url.includes(
            Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL
          ) ||
            response.config.url.includes(
              Vue.prototype.$config.VUE_APP_PROGRAM_API_BASE_URL
            ))
        ) {
          // Unauthorized Or Expired from backoffice api
          store.dispatch("auth/logout", this.$ability);
        }
        return Promise.reject(error);
      }
    );
  }
}
