export default [
  // GESTION
  {
    path: "/situation/manage",
    name: "situation-manage",
    component: () => import("@/views/api-manage/ApiManage.vue"),
    meta: {
      resource: "APIManagementPages",
      pageTitle: "Gestion - API Emplacement",
      contentClass: "itemgrid-application",
      navActiveLink: "situation-manage",
    },
  },
  // DOCUMENTATION
  {
    path: "/situation/redoc",
    name: "situation-redoc",
    component: () => import("@/views/redoc/Redoc.vue"),
    meta: {
      resource: "SituationPages",
      pageTitle: "Situation Redoc",
    },
  },
];
