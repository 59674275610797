export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      resource: "Public",
    },
  },
  {
    path: "/reset-password/:email",
    name: "reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Public",
    },
  },
  {
    path: "/update-password",
    name: "update-password",
    component: () => import("@/views/auth/UpdatePassword.vue"),
    meta: {
      layout: "full",
      resource: "Public",
    },
  },
];
