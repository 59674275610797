export default [
  {
    path: "/board/public",
    name: "board-public",
    component: () => import("@/views/boards/PublicBoard.vue"),
    meta: {
      resource: "PublicBoard",
      pageTitle: "Aperçu",
    },
  },
];
