export default [
  {
    path: "/products",
    name: "product-list",
    component: () => import("@/views/products/product-list/ProductList.vue"),
    meta: {
      resource: "ProductPages",
      pageTitle: "Produits",
      contentClass: "itemgrid-application",
    },
  },
  {
    path: "/products/detail/:id",
    name: "product-detail",
    component: () =>
      import("@/views/products/product-detail/ProductDetail.vue"),
    meta: {
      resource: "ProductPages",
      pageTitle: "Détails",
      contentClass: "itemgrid-application",
      navActiveLink: "product-list",
    },
  },
  {
    path: "/products/edit/:id",
    name: "product-edit",
    component: () => import("@/views/products/product-edit/ProductEdit.vue"),
    meta: {
      resource: "ProductManagementPages",
      pageTitle: "Edition",
      navActiveLink: "product-list",
    },
  },
  {
    path: "/products/create",
    name: "product-create",
    component: () =>
      import("@/views/products/product-create/ProductCreate.vue"),
    meta: {
      resource: "ProductManagementPages",
      pageTitle: "Création",
      navActiveLink: "product-list",
    },
  },
];
