import Vue from "vue";
import * as Api from "../../axios-generated/backoffice";
import axios from "@axios";

const getProductAPI = () => {
  return new Api.ProductsApi(
    null,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL
      : "",
    axios
  );
};

const getSubscriptionAPI = () => {
  return new Api.SubscriptionsApi(
    null,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL
      : "",
    axios
  );
};

const getDefaultState = () => {
  return {
    products: [],
  };
};

export default {
  namespaced: true,
  state: {
    products: [],
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setProducts(state, val) {
      state.products = val;
    },
  },
  actions: {
    async resetModuleState({ commit }) {
      commit("resetState");
    },
    async fetchProducts({ commit }) {
      return new Promise((resolve, reject) => {
        getProductAPI()
          .findProducts()
          .then((response) => {
            // set products in state
            commit("setProducts", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async findSubscriptionsByProductIDAndInvoiceAccountID(ctx, params) {
      return new Promise((resolve, reject) => {
        getSubscriptionAPI()
          .findSubscriptionsByProductIDAndInvoiceAccountID(
            params.productID,
            params.invoiceAccountID
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async findAPIKeysBySubscriptionID(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        getSubscriptionAPI()
          .findAPIKeysBySubscriptionID(
            queryParams.subscriptionId,
            queryParams.sortBy,
            queryParams.perPage,
            queryParams.page,
            queryParams.sortDesc
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async createSubscription(ctx, { newSubscription }) {
      return new Promise((resolve, reject) => {
        getSubscriptionAPI()
          .createSubscription(newSubscription)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
