import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import products from "./products";
import invoiceAccounts from "./invoice-accounts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    products,
    invoiceAccounts,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  strict: process.env.DEV ? process.env.DEV.toLowerCase() === "true" : true,
});
