export default [
  // GESTION
  {
    path: "/estimate/manage",
    name: "estimate-manage",
    component: () => import("@/views/api-manage/ApiManage.vue"),
    meta: {
      resource: "APIManagementPages",
      pageTitle: "Gestion - API Estimation",
      contentClass: "itemgrid-application",
      navActiveLink: "estimate-manage",
    },
  },
  // DOCUMENTATION
  {
    path: "/estimate/redoc",
    name: "estimate-redoc",
    component: () => import("@/views/redoc/Redoc.vue"),
    meta: {
      resource: "EstimatePages",
      pageTitle: "Estimate Redoc",
    },
  },
  // DEMO
  {
    path: "/estimate/demo",
    name: "estimate-demo",
    component: () => import("@/views/estimate/demo/Estimate.vue"),
    meta: {
      resource: "EstimatePages",
      pageTitle: "Estimate Démo",
      contentClass: "itemgrid-application",
    },
  },
];
