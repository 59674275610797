import { Ability } from "@casl/ability";
import {
  publicAbility,
  businessCustomerAbility,
  businessAdminAbility,
  boCustomerAbility,
  boAdminAbility,
} from "./config";
import store from "@/store";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userRole = store ? store.state.auth.userProfile.role : "UNKNOWN";

export const fetchAbility = (userRole) => {
  let ability = null;
  switch (userRole) {
    case "ADMIN_BO":
      ability = businessCustomerAbility
        .concat(businessAdminAbility)
        .concat(boCustomerAbility)
        .concat(boAdminAbility);
      break;
    case "BUSINESS_BO":
      ability = businessCustomerAbility.concat(boCustomerAbility);
      break;
    case "ADMIN_CUSTOMER":
      ability = businessCustomerAbility.concat(businessAdminAbility);
      break;
    case "BUSINESS_CUSTOMER":
      ability = businessCustomerAbility;
      break;
    default:
      ability = publicAbility;
  }
  return ability;
};

const existingAbility = fetchAbility(userRole);

export default new Ability(existingAbility || publicAbility);
