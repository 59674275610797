export default [
  {
    path: "/flows",
    name: "flow-list",
    component: () => import("@/views/flows/flow-list/FlowList.vue"),
    meta: {
      resource: "FlowPages",
      pageTitle: "Flux",
    },
  },
  {
    path: "/flows/detail/:id",
    name: "flow-detail",
    component: () => import("@/views/flows/flow-detail/FlowDetail.vue"),
    meta: {
      resource: "FlowManagementPages",
      pageTitle: "Détails",
      navActiveLink: "flow-list",
    },
  },
  {
    path: "/flows/create",
    name: "flow-create",
    component: () => import("@/views/flows/flow-create/FlowCreate.vue"),
    meta: {
      resource: "FlowManagementPages",
      pageTitle: "Création",
      navActiveLink: "flow-list",
    },
  },
];
