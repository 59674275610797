import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  timeout: 15000,
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
