export default [
  // RECHERCHE
  {
    path: "/decimmo/search",
    name: "decimmo-searcher",
    component: () => import("@/views/decimmo/searcher/Searcher.vue"),
    meta: {
      resource: "DecimmoPages",
      pageTitle: "Decision Immo Searcher",
      contentRenderer: "sidebar-left-detached",
      contentClass: "itemgrid-application",
    },
  },
  // COMPARATOR
  {
    path: "/decimmo/comparator",
    name: "decimmo-comparator",
    component: () => import("@/views/decimmo/comparator/Comparator.vue"),
    meta: {
      resource: "DecimmoPages",
      pageTitle: "Decision Immo Comparator",
      navActiveLink: "decimmo-searcher",
    },
  },
  // MES ETUDES
  {
    path: "/decimmo/records",
    name: "decimmo-records",
    component: () => import("@/views/decimmo/records/Records.vue"),
    meta: {
      resource: "DecimmoPages",
      pageTitle: "Decision Immo Records",
      navActiveLink: "decimmo-records",
    },
  },
  {
    path: "/decimmo/records/program",
    name: "decimmo-report",
    component: () => import("@/views/decimmo/report/Report.vue"),
    meta: {
      resource: "DecimmoPages",
      pageTitle: "Decision Immo Report",
      navActiveLink: "decimmo-searcher",
    },
  },
  {
    path: "/decimmo/records/position",
    name: "decimmo-report-position",
    component: () => import("@/views/decimmo/report/ReportPosition.vue"),
    meta: {
      resource: "DecimmoPages",
      pageTitle: "Decision Immo Report Position",
      navActiveLink: "decimmo-searcher",
    },
  },
];
