/* tslint:disable */
/* eslint-disable */
/**
 * API BackOffice
 * L\'API BackOffice permet de gérer l\'ensemble du métier Cadre de Vie, avec une administration admin et utilisateur.  # Authentication  Authentification par token JWT Firebase sur le backoffice.  <!-- ReDoc-Inject: <security-definitions> -->
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: cdv-si@cadredevie.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Ad
 */
export interface Ad {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Ad
     */
    'id'?: number;
    /**
     * Is enable
     * @type {number}
     * @memberof Ad
     */
    'enabled'?: number;
    /**
     * Flow origin
     * @type {string}
     * @memberof Ad
     */
    'flow_origin'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof Ad
     */
    'update_at'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof Ad
     */
    'integration_date'?: string;
    /**
     * Flux provider DB Unic reference
     * @type {string}
     * @memberof Ad
     */
    'flow_ID'?: string;
    /**
     * Program provider DB Unic reference
     * @type {number}
     * @memberof Ad
     */
    'program_ID'?: number;
    /**
     * Developer provider DB Unic reference
     * @type {number}
     * @memberof Ad
     */
    'developer_ID'?: number;
    /**
     * Ubiflow ID
     * @type {string}
     * @memberof Ad
     */
    'ubiflow_ID'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof Ad
     */
    'availability_date'?: string;
    /**
     * Free description
     * @type {string}
     * @memberof Ad
     */
    'lot_availability'?: string;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof Ad
     */
    'title'?: string;
    /**
     * Long text description
     * @type {string}
     * @memberof Ad
     */
    'description'?: string;
    /**
     * Reference of the property
     * @type {string}
     * @memberof Ad
     */
    'reference'?: string;
    /**
     * Property type by category
     * @type {string}
     * @memberof Ad
     */
    'type_parent'?: string;
    /**
     * Owner type
     * @type {string}
     * @memberof Ad
     */
    'owner_type'?: string;
    /**
     * floor
     * @type {number}
     * @memberof Ad
     */
    'floor'?: number;
    /**
     * Number of floors
     * @type {number}
     * @memberof Ad
     */
    'nb_floors'?: number;
    /**
     * Year of construction
     * @type {string}
     * @memberof Ad
     */
    'year_of_construction'?: string;
    /**
     * exposition
     * @type {string}
     * @memberof Ad
     */
    'exposure'?: string;
    /**
     * Net price
     * @type {number}
     * @memberof Ad
     */
    'price'?: number;
    /**
     * Price excluding fees
     * @type {number}
     * @memberof Ad
     */
    'price_excl_fees'?: number;
    /**
     * Label of the payer(s) (in French)
     * @type {string}
     * @memberof Ad
     */
    'payer_fees'?: string;
    /**
     * Must the buyer pay the fees?
     * @type {number}
     * @memberof Ad
     */
    'buyer_fees'?: number;
    /**
     * Must the seller pay the fees?
     * @type {number}
     * @memberof Ad
     */
    'seller_fees'?: number;
    /**
     * Percentage fees payed by the buyer (\"5.2\" is 5.2%)
     * @type {number}
     * @memberof Ad
     */
    'percentage_buyer_fees'?: number;
    /**
     * Percentage fees payed by the seller (\"5.2\" is 5.2%)
     * @type {number}
     * @memberof Ad
     */
    'percentage_seller_fees'?: number;
    /**
     * URL of the realtor public tariff (legal notice)
     * @type {string}
     * @memberof Ad
     */
    'urlPublicTariff'?: string;
    /**
     * Property furniture price ttc
     * @type {number}
     * @memberof Ad
     */
    'furniture_price_ttc'?: number;
    /**
     * Property price ttc
     * @type {number}
     * @memberof Ad
     */
    'property_price_ttc'?: number;
    /**
     * Property furniture price ttc
     * @type {number}
     * @memberof Ad
     */
    'furniture_price_ht'?: number;
    /**
     * Property price ttc
     * @type {number}
     * @memberof Ad
     */
    'property_price_ht'?: number;
    /**
     * Tax rate
     * @type {number}
     * @memberof Ad
     */
    'tax_rate'?: number;
    /**
     * Profitability rate
     * @type {number}
     * @memberof Ad
     */
    'profit_rate'?: number;
    /**
     * Renovation rate
     * @type {number}
     * @memberof Ad
     */
    'renovation_rate'?: number;
    /**
     * Rent price
     * @type {number}
     * @memberof Ad
     */
    'rent'?: number;
    /**
     * Additionnal cost to the rent price
     * @type {number}
     * @memberof Ad
     */
    'charges'?: number;
    /**
     * Sell or rent
     * @type {string}
     * @memberof Ad
     */
    'service'?: string;
    /**
     * mandate_type
     * @type {string}
     * @memberof Ad
     */
    'mandate_type'?: string;
    /**
     * Total area
     * @type {number}
     * @memberof Ad
     */
    'area'?: number;
    /**
     * Stay area
     * @type {number}
     * @memberof Ad
     */
    'stay_area'?: number;
    /**
     * Terrace area
     * @type {number}
     * @memberof Ad
     */
    'terrace_area'?: number;
    /**
     * Balcony area
     * @type {number}
     * @memberof Ad
     */
    'balcony_area'?: number;
    /**
     * Garden area
     * @type {number}
     * @memberof Ad
     */
    'garden_area'?: number;
    /**
     * Address of the agency
     * @type {string}
     * @memberof Ad
     */
    'street'?: string;
    /**
     * City location of the property
     * @type {string}
     * @memberof Ad
     */
    'city'?: string;
    /**
     * Zipcode of the city
     * @type {string}
     * @memberof Ad
     */
    'zip'?: string;
    /**
     * Country
     * @type {string}
     * @memberof Ad
     */
    'country'?: string;
    /**
     * latitude
     * @type {number}
     * @memberof Ad
     */
    'latitude'?: number;
    /**
     * longitude (\'lng\' for Google Maps API... but use \'lon\' for geoJSON, elastic or GIS...)
     * @type {number}
     * @memberof Ad
     */
    'longitude'?: number;
    /**
     * Number of standard rooms
     * @type {number}
     * @memberof Ad
     */
    'nb_rooms'?: number;
    /**
     * Number of standard bedrooms
     * @type {number}
     * @memberof Ad
     */
    'nb_bed_rooms'?: number;
    /**
     * Number of bathrooms
     * @type {number}
     * @memberof Ad
     */
    'nb_bathrooms'?: number;
    /**
     * Number of water rooms
     * @type {number}
     * @memberof Ad
     */
    'nb_water_rooms'?: number;
    /**
     * kitchen
     * @type {string}
     * @memberof Ad
     */
    'kitchen'?: string;
    /**
     * has terrace
     * @type {number}
     * @memberof Ad
     */
    'terrace'?: number;
    /**
     * is furnished
     * @type {number}
     * @memberof Ad
     */
    'furnished'?: number;
    /**
     * has cellar
     * @type {number}
     * @memberof Ad
     */
    'cellar'?: number;
    /**
     * has elevator
     * @type {number}
     * @memberof Ad
     */
    'elevator'?: number;
    /**
     * has fireplace
     * @type {number}
     * @memberof Ad
     */
    'fireplace'?: number;
    /**
     * has veranda
     * @type {number}
     * @memberof Ad
     */
    'veranda'?: number;
    /**
     * has garage
     * @type {number}
     * @memberof Ad
     */
    'garage'?: number;
    /**
     * has swimming_pool
     * @type {number}
     * @memberof Ad
     */
    'swimming_pool'?: number;
    /**
     * has green_spaces
     * @type {number}
     * @memberof Ad
     */
    'green_spaces'?: number;
    /**
     * has loggia
     * @type {number}
     * @memberof Ad
     */
    'loggia'?: number;
    /**
     * has balcony
     * @type {number}
     * @memberof Ad
     */
    'balcony'?: number;
    /**
     * has private_garden
     * @type {number}
     * @memberof Ad
     */
    'private_garden'?: number;
    /**
     * has videocom
     * @type {number}
     * @memberof Ad
     */
    'videocom'?: number;
    /**
     * has fibre
     * @type {string}
     * @memberof Ad
     */
    'fibre'?: string;
    /**
     * heating_type
     * @type {string}
     * @memberof Ad
     */
    'heating_type'?: string;
    /**
     * heating_energy
     * @type {string}
     * @memberof Ad
     */
    'heating_energy'?: string;
    /**
     * heating_mechanism
     * @type {string}
     * @memberof Ad
     */
    'heating_mechanism'?: string;
    /**
     * Energy consumption label (legal notice)
     * @type {string}
     * @memberof Ad
     */
    'dpe_label_consumption'?: string;
    /**
     * Energy consumption vale (legal notice)
     * @type {number}
     * @memberof Ad
     */
    'dpe_value_consumption'?: number;
    /**
     * Gas emission label (legal notice)
     * @type {string}
     * @memberof Ad
     */
    'dpe_label_gaz'?: string;
    /**
     * Gas emission vale (legal notice)
     * @type {number}
     * @memberof Ad
     */
    'dpe_value_gaz'?: number;
    /**
     * Medias
     * @type {Array<Media>}
     * @memberof Ad
     */
    'medias'?: Array<Media>;
}
/**
 * 
 * @export
 * @interface AdProgram
 */
export interface AdProgram {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof AdProgram
     */
    'id'?: number;
    /**
     * Is enable
     * @type {number}
     * @memberof AdProgram
     */
    'enabled'?: number;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof AdProgram
     */
    'title'?: string;
    /**
     * Long text description
     * @type {string}
     * @memberof AdProgram
     */
    'description'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgram
     */
    'update_at'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgram
     */
    'integration_date'?: string;
    /**
     * Virtual visit
     * @type {string}
     * @memberof AdProgram
     */
    'virtual_visit'?: string;
    /**
     * Street
     * @type {string}
     * @memberof AdProgram
     */
    'street'?: string;
    /**
     * Zipcode of the city
     * @type {string}
     * @memberof AdProgram
     */
    'zip'?: string;
    /**
     * City location of the program
     * @type {string}
     * @memberof AdProgram
     */
    'city'?: string;
    /**
     * Country
     * @type {string}
     * @memberof AdProgram
     */
    'country'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgram
     */
    'delivery_date'?: string;
    /**
     * Free description
     * @type {string}
     * @memberof AdProgram
     */
    'heating_type'?: string;
    /**
     * Free description
     * @type {string}
     * @memberof AdProgram
     */
    'heating_energy'?: string;
    /**
     * Free description
     * @type {string}
     * @memberof AdProgram
     */
    'heating_mechanism'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgram
     */
    'commercialisation_date'?: string;
    /**
     * Minimal area
     * @type {number}
     * @memberof AdProgram
     */
    'area_min'?: number;
    /**
     * Maximal area
     * @type {number}
     * @memberof AdProgram
     */
    'area_max'?: number;
    /**
     * Minimum price
     * @type {number}
     * @memberof AdProgram
     */
    'price_min'?: number;
    /**
     * Maximum price
     * @type {number}
     * @memberof AdProgram
     */
    'price_max'?: number;
    /**
     * Number of properties
     * @type {number}
     * @memberof AdProgram
     */
    'nb_properties'?: number;
    /**
     * Number of available properties
     * @type {number}
     * @memberof AdProgram
     */
    'nb_available_properties'?: number;
    /**
     * Number of standard rooms
     * @type {string}
     * @memberof AdProgram
     */
    'nb_rooms'?: string;
    /**
     * Total rooms minimal
     * @type {number}
     * @memberof AdProgram
     */
    'nb_rooms_min'?: number;
    /**
     * Total rooms maximal
     * @type {number}
     * @memberof AdProgram
     */
    'nb_rooms_max'?: number;
    /**
     * Tax exemption
     * @type {string}
     * @memberof AdProgram
     */
    'tax_exemption'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgram
     */
    'offer_start_date'?: string;
    /**
     * Developer ID
     * @type {number}
     * @memberof AdProgram
     */
    'developer_ID'?: number;
    /**
     * latitude
     * @type {number}
     * @memberof AdProgram
     */
    'latitude'?: number;
    /**
     * longitude (\'lng\' for Google Maps API... but use \'lon\' for geoJSON, elastic or GIS...)
     * @type {number}
     * @memberof AdProgram
     */
    'longitude'?: number;
    /**
     * Ubiflow ID
     * @type {string}
     * @memberof AdProgram
     */
    'ubiflow_ID'?: string;
    /**
     * Flow ID
     * @type {string}
     * @memberof AdProgram
     */
    'flow_ID'?: string;
    /**
     * Flow origin
     * @type {string}
     * @memberof AdProgram
     */
    'flow_origin'?: string;
    /**
     * Has elevator
     * @type {number}
     * @memberof AdProgram
     */
    'elevator'?: number;
    /**
     * Energy consumption label (legal notice)
     * @type {string}
     * @memberof AdProgram
     */
    'dpe_label_consumption'?: string;
    /**
     * Energy consumption vale (legal notice)
     * @type {number}
     * @memberof AdProgram
     */
    'dpe_value_consumption'?: number;
    /**
     * Gas emission label (legal notice)
     * @type {string}
     * @memberof AdProgram
     */
    'dpe_label_gaz'?: string;
    /**
     * Gas emission vale (legal notice)
     * @type {number}
     * @memberof AdProgram
     */
    'dpe_value_gaz'?: number;
    /**
     * Medias
     * @type {Array<Media>}
     * @memberof AdProgram
     */
    'medias'?: Array<Media>;
}
/**
 * 
 * @export
 * @interface AdProgramLot
 */
export interface AdProgramLot {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof AdProgramLot
     */
    'id'?: number;
    /**
     * Is enable
     * @type {number}
     * @memberof AdProgramLot
     */
    'enabled'?: number;
    /**
     * Flux provider DB Unic reference
     * @type {string}
     * @memberof AdProgramLot
     */
    'flow_ID'?: string;
    /**
     * Flow origin
     * @type {string}
     * @memberof AdProgramLot
     */
    'flow_origin'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgramLot
     */
    'update_at'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgramLot
     */
    'integration_date'?: string;
    /**
     * Program provider DB Unic reference
     * @type {number}
     * @memberof AdProgramLot
     */
    'program_ID'?: number;
    /**
     * Developer provider DB Unic reference
     * @type {number}
     * @memberof AdProgramLot
     */
    'developer_ID'?: number;
    /**
     * Ubiflow ID
     * @type {string}
     * @memberof AdProgramLot
     */
    'ubiflow_ID'?: string;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof AdProgramLot
     */
    'availability_date'?: string;
    /**
     * Free description
     * @type {string}
     * @memberof AdProgramLot
     */
    'lot_availability'?: string;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof AdProgramLot
     */
    'title'?: string;
    /**
     * Long text description
     * @type {string}
     * @memberof AdProgramLot
     */
    'description'?: string;
    /**
     * Reference of the property
     * @type {string}
     * @memberof AdProgramLot
     */
    'reference'?: string;
    /**
     * Property type by category
     * @type {string}
     * @memberof AdProgramLot
     */
    'type_parent'?: string;
    /**
     * Owner type
     * @type {string}
     * @memberof AdProgramLot
     */
    'owner_type'?: string;
    /**
     * floor
     * @type {number}
     * @memberof AdProgramLot
     */
    'floor'?: number;
    /**
     * Number of floors
     * @type {number}
     * @memberof AdProgramLot
     */
    'nb_floors'?: number;
    /**
     * Year of construction
     * @type {string}
     * @memberof AdProgramLot
     */
    'year_of_construction'?: string;
    /**
     * exposition
     * @type {string}
     * @memberof AdProgramLot
     */
    'exposure'?: string;
    /**
     * Net price
     * @type {number}
     * @memberof AdProgramLot
     */
    'price'?: number;
    /**
     * Price excluding fees
     * @type {number}
     * @memberof AdProgramLot
     */
    'price_excl_fees'?: number;
    /**
     * Label of the payer(s) (in French)
     * @type {string}
     * @memberof AdProgramLot
     */
    'payer_fees'?: string;
    /**
     * Must the buyer pay the fees?
     * @type {number}
     * @memberof AdProgramLot
     */
    'buyer_fees'?: number;
    /**
     * Must the seller pay the fees?
     * @type {number}
     * @memberof AdProgramLot
     */
    'seller_fees'?: number;
    /**
     * Percentage fees payed by the buyer (\"5.2\" is 5.2%)
     * @type {number}
     * @memberof AdProgramLot
     */
    'percentage_buyer_fees'?: number;
    /**
     * Percentage fees payed by the seller (\"5.2\" is 5.2%)
     * @type {number}
     * @memberof AdProgramLot
     */
    'percentage_seller_fees'?: number;
    /**
     * URL of the realtor public tariff (legal notice)
     * @type {string}
     * @memberof AdProgramLot
     */
    'urlPublicTariff'?: string;
    /**
     * Property furniture price ttc
     * @type {number}
     * @memberof AdProgramLot
     */
    'furniture_price_ttc'?: number;
    /**
     * Property price ttc
     * @type {number}
     * @memberof AdProgramLot
     */
    'property_price_ttc'?: number;
    /**
     * Property furniture price ttc
     * @type {number}
     * @memberof AdProgramLot
     */
    'furniture_price_ht'?: number;
    /**
     * Property price ttc
     * @type {number}
     * @memberof AdProgramLot
     */
    'property_price_ht'?: number;
    /**
     * Tax rate
     * @type {number}
     * @memberof AdProgramLot
     */
    'tax_rate'?: number;
    /**
     * Profitability rate
     * @type {number}
     * @memberof AdProgramLot
     */
    'profit_rate'?: number;
    /**
     * Renovation rate
     * @type {number}
     * @memberof AdProgramLot
     */
    'renovation_rate'?: number;
    /**
     * Rent price
     * @type {number}
     * @memberof AdProgramLot
     */
    'rent'?: number;
    /**
     * Additionnal cost to the rent price
     * @type {number}
     * @memberof AdProgramLot
     */
    'charges'?: number;
    /**
     * Sell or rent
     * @type {string}
     * @memberof AdProgramLot
     */
    'service'?: string;
    /**
     * mandate_type
     * @type {string}
     * @memberof AdProgramLot
     */
    'mandate_type'?: string;
    /**
     * Total area
     * @type {number}
     * @memberof AdProgramLot
     */
    'area'?: number;
    /**
     * Stay area
     * @type {number}
     * @memberof AdProgramLot
     */
    'stay_area'?: number;
    /**
     * Terrace area
     * @type {number}
     * @memberof AdProgramLot
     */
    'terrace_area'?: number;
    /**
     * Balcony area
     * @type {number}
     * @memberof AdProgramLot
     */
    'balcony_area'?: number;
    /**
     * Garden area
     * @type {number}
     * @memberof AdProgramLot
     */
    'garden_area'?: number;
    /**
     * Address of the agency
     * @type {string}
     * @memberof AdProgramLot
     */
    'street'?: string;
    /**
     * City location of the property
     * @type {string}
     * @memberof AdProgramLot
     */
    'city'?: string;
    /**
     * Zipcode of the city
     * @type {string}
     * @memberof AdProgramLot
     */
    'zip'?: string;
    /**
     * Country
     * @type {string}
     * @memberof AdProgramLot
     */
    'country'?: string;
    /**
     * latitude
     * @type {number}
     * @memberof AdProgramLot
     */
    'latitude'?: number;
    /**
     * longitude (\'lng\' for Google Maps API... but use \'lon\' for geoJSON, elastic or GIS...)
     * @type {number}
     * @memberof AdProgramLot
     */
    'longitude'?: number;
    /**
     * Number of standard rooms
     * @type {number}
     * @memberof AdProgramLot
     */
    'nb_rooms'?: number;
    /**
     * Number of standard bedrooms
     * @type {number}
     * @memberof AdProgramLot
     */
    'nb_bed_rooms'?: number;
    /**
     * Number of bathrooms
     * @type {number}
     * @memberof AdProgramLot
     */
    'nb_bathrooms'?: number;
    /**
     * Number of water rooms
     * @type {number}
     * @memberof AdProgramLot
     */
    'nb_water_rooms'?: number;
    /**
     * kitchen
     * @type {string}
     * @memberof AdProgramLot
     */
    'kitchen'?: string;
    /**
     * has terrace
     * @type {number}
     * @memberof AdProgramLot
     */
    'terrace'?: number;
    /**
     * is furnished
     * @type {number}
     * @memberof AdProgramLot
     */
    'furnished'?: number;
    /**
     * has cellar
     * @type {number}
     * @memberof AdProgramLot
     */
    'cellar'?: number;
    /**
     * has elevator
     * @type {number}
     * @memberof AdProgramLot
     */
    'elevator'?: number;
    /**
     * has fireplace
     * @type {number}
     * @memberof AdProgramLot
     */
    'fireplace'?: number;
    /**
     * has veranda
     * @type {number}
     * @memberof AdProgramLot
     */
    'veranda'?: number;
    /**
     * has garage
     * @type {number}
     * @memberof AdProgramLot
     */
    'garage'?: number;
    /**
     * has swimming_pool
     * @type {number}
     * @memberof AdProgramLot
     */
    'swimming_pool'?: number;
    /**
     * has green_spaces
     * @type {number}
     * @memberof AdProgramLot
     */
    'green_spaces'?: number;
    /**
     * has loggia
     * @type {number}
     * @memberof AdProgramLot
     */
    'loggia'?: number;
    /**
     * has balcony
     * @type {number}
     * @memberof AdProgramLot
     */
    'balcony'?: number;
    /**
     * has private_garden
     * @type {number}
     * @memberof AdProgramLot
     */
    'private_garden'?: number;
    /**
     * has videocom
     * @type {number}
     * @memberof AdProgramLot
     */
    'videocom'?: number;
    /**
     * has fibre
     * @type {string}
     * @memberof AdProgramLot
     */
    'fibre'?: string;
    /**
     * heating_type
     * @type {string}
     * @memberof AdProgramLot
     */
    'heating_type'?: string;
    /**
     * heating_energy
     * @type {string}
     * @memberof AdProgramLot
     */
    'heating_energy'?: string;
    /**
     * heating_mechanism
     * @type {string}
     * @memberof AdProgramLot
     */
    'heating_mechanism'?: string;
    /**
     * Energy consumption label (legal notice)
     * @type {string}
     * @memberof AdProgramLot
     */
    'dpe_label_consumption'?: string;
    /**
     * Energy consumption vale (legal notice)
     * @type {number}
     * @memberof AdProgramLot
     */
    'dpe_value_consumption'?: number;
    /**
     * Gas emission label (legal notice)
     * @type {string}
     * @memberof AdProgramLot
     */
    'dpe_label_gaz'?: string;
    /**
     * Gas emission vale (legal notice)
     * @type {number}
     * @memberof AdProgramLot
     */
    'dpe_value_gaz'?: number;
    /**
     * Medias
     * @type {Array<Media>}
     * @memberof AdProgramLot
     */
    'medias'?: Array<Media>;
}
/**
 * 
 * @export
 * @interface Apikey
 */
export interface Apikey {
    /**
     * Name of the ApiKey
     * @type {string}
     * @memberof Apikey
     */
    'name': string;
    /**
     * Validity date until the apikey is enabled (Format ISO 8601)
     * @type {string}
     * @memberof Apikey
     */
    'validity_date'?: string;
    /**
     * Id of the subscription linked with the ApiKey
     * @type {string}
     * @memberof Apikey
     */
    'subscription_ID': string;
    /**
     * White list for authorized domains or IPs (separated by \";\")
     * @type {string}
     * @memberof Apikey
     */
    'white_list'?: string;
    /**
     * Unique id (format UUID) of the Product
     * @type {string}
     * @memberof Apikey
     */
    'ID'?: string;
    /**
     * Activate Apikey
     * @type {boolean}
     * @memberof Apikey
     */
    'enabled'?: boolean;
    /**
     * Id of the invoice account linked with the ApiKey
     * @type {string}
     * @memberof Apikey
     */
    'invoice_account_ID': string;
    /**
     * Token for external call (jwt)
     * @type {string}
     * @memberof Apikey
     */
    'token'?: string;
    /**
     * Created ApiKey date (Format ISO 8601)
     * @type {string}
     * @memberof Apikey
     */
    'created_at'?: string;
    /**
     * Last update ApiKey date (Format ISO 8601)
     * @type {string}
     * @memberof Apikey
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ApikeyAllOf
 */
export interface ApikeyAllOf {
    /**
     * Unique id (format UUID) of the Product
     * @type {string}
     * @memberof ApikeyAllOf
     */
    'ID'?: string;
    /**
     * Activate Apikey
     * @type {boolean}
     * @memberof ApikeyAllOf
     */
    'enabled'?: boolean;
    /**
     * Id of the invoice account linked with the ApiKey
     * @type {string}
     * @memberof ApikeyAllOf
     */
    'invoice_account_ID'?: string;
    /**
     * Token for external call (jwt)
     * @type {string}
     * @memberof ApikeyAllOf
     */
    'token'?: string;
    /**
     * Created ApiKey date (Format ISO 8601)
     * @type {string}
     * @memberof ApikeyAllOf
     */
    'created_at'?: string;
    /**
     * Last update ApiKey date (Format ISO 8601)
     * @type {string}
     * @memberof ApikeyAllOf
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * Name of the company
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * Code of the company
     * @type {string}
     * @memberof Company
     */
    'code': string;
    /**
     * Description of the company
     * @type {string}
     * @memberof Company
     */
    'description': string;
    /**
     * Contact email of the company
     * @type {string}
     * @memberof Company
     */
    'email': string;
    /**
     * First field Address of the company
     * @type {string}
     * @memberof Company
     */
    'address_1': string;
    /**
     * Second field Address of the company
     * @type {string}
     * @memberof Company
     */
    'address_2'?: string;
    /**
     * Address Zip Code of the company
     * @type {string}
     * @memberof Company
     */
    'zip_code': string;
    /**
     * Country of the company
     * @type {string}
     * @memberof Company
     */
    'country': string;
    /**
     * Legal contact of the company
     * @type {string}
     * @memberof Company
     */
    'contact_legal': string;
    /**
     * Legal contact of the company
     * @type {string}
     * @memberof Company
     */
    'registration_number': string;
    /**
     * Contact url of the company
     * @type {string}
     * @memberof Company
     */
    'URL': string;
    /**
     * Link to the logo of the company
     * @type {string}
     * @memberof Company
     */
    'logo': string;
    /**
     * VAT of the comapny
     * @type {string}
     * @memberof Company
     */
    'vat'?: string;
    /**
     * Type of the company
     * @type {string}
     * @memberof Company
     */
    'business_domain': CompanyBusinessDomainEnum;
    /**
     * Status of the company
     * @type {string}
     * @memberof Company
     */
    'status': CompanyStatusEnum;
    /**
     * Unique id (format UUID) of the company
     * @type {string}
     * @memberof Company
     */
    'ID'?: string;
    /**
     * Indicate if the company is provider
     * @type {boolean}
     * @memberof Company
     */
    'root'?: boolean;
    /**
     * Created user date (Format ISO 8601)
     * @type {string}
     * @memberof Company
     */
    'created_at'?: string;
    /**
     * Last update user date (Format ISO 8601)
     * @type {string}
     * @memberof Company
     */
    'updated_at'?: string;
}

export const CompanyBusinessDomainEnum = {
    None: 'NONE',
    Bank: 'BANK',
    Insurance: 'INSURANCE'
} as const;

export type CompanyBusinessDomainEnum = typeof CompanyBusinessDomainEnum[keyof typeof CompanyBusinessDomainEnum];
export const CompanyStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type CompanyStatusEnum = typeof CompanyStatusEnum[keyof typeof CompanyStatusEnum];

/**
 * 
 * @export
 * @interface CompanyAllOf
 */
export interface CompanyAllOf {
    /**
     * Unique id (format UUID) of the company
     * @type {string}
     * @memberof CompanyAllOf
     */
    'ID'?: string;
    /**
     * Indicate if the company is provider
     * @type {boolean}
     * @memberof CompanyAllOf
     */
    'root'?: boolean;
    /**
     * Created user date (Format ISO 8601)
     * @type {string}
     * @memberof CompanyAllOf
     */
    'created_at'?: string;
    /**
     * Last update user date (Format ISO 8601)
     * @type {string}
     * @memberof CompanyAllOf
     */
    'updated_at'?: string;
}
/**
 * Contact
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * Firstname of the contact
     * @type {string}
     * @memberof Contact
     */
    'first_name': string;
    /**
     * LastName of the contact
     * @type {string}
     * @memberof Contact
     */
    'last_name': string;
    /**
     * Email of the contact
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
    /**
     * Mobile of the contact
     * @type {string}
     * @memberof Contact
     */
    'mobile'?: string;
    /**
     * Id of the project linked with the contact (required if flow_id null)
     * @type {string}
     * @memberof Contact
     */
    'project_ID'?: string;
    /**
     * Id of the flow linked with the contact (required if project_ID null)
     * @type {string}
     * @memberof Contact
     */
    'flow_ID'?: string;
    /**
     * Gender of the contact
     * @type {string}
     * @memberof Contact
     */
    'gender': ContactGenderEnum;
    /**
     * Type of the contact
     * @type {string}
     * @memberof Contact
     */
    'job'?: string;
    /**
     * Unique id (format UUID) of the Contact
     * @type {string}
     * @memberof Contact
     */
    'ID'?: string;
    /**
     * Created date (Format ISO 8601)
     * @type {string}
     * @memberof Contact
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Contact
     */
    'updated_at'?: string;
}

export const ContactGenderEnum = {
    Other: 'OTHER',
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type ContactGenderEnum = typeof ContactGenderEnum[keyof typeof ContactGenderEnum];

/**
 * 
 * @export
 * @interface ContactAllOf
 */
export interface ContactAllOf {
    /**
     * Unique id (format UUID) of the Contact
     * @type {string}
     * @memberof ContactAllOf
     */
    'ID'?: string;
    /**
     * Created date (Format ISO 8601)
     * @type {string}
     * @memberof ContactAllOf
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof ContactAllOf
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface Flow
 */
export interface Flow {
    /**
     * Name of the flow
     * @type {string}
     * @memberof Flow
     */
    'name': string;
    /**
     * Description of the flow
     * @type {string}
     * @memberof Flow
     */
    'description': string;
    /**
     * Link to the logo of the flow provider
     * @type {string}
     * @memberof Flow
     */
    'logo': string;
    /**
     * Code origin of the flow
     * @type {string}
     * @memberof Flow
     */
    'flow_origin': string;
    /**
     * Job name of the flow
     * @type {string}
     * @memberof Flow
     */
    'job_name': string;
    /**
     * Type of the offers in the flow
     * @type {string}
     * @memberof Flow
     */
    'type_offers': FlowTypeOffersEnum;
    /**
     * Format of the data flow
     * @type {string}
     * @memberof Flow
     */
    'format': FlowFormatEnum;
    /**
     * Status of the flow
     * @type {string}
     * @memberof Flow
     */
    'status': FlowStatusEnum;
    /**
     * Unique id (format UUID) of the flow
     * @type {string}
     * @memberof Flow
     */
    'ID'?: string;
    /**
     * Created flow date (Format ISO 8601)
     * @type {string}
     * @memberof Flow
     */
    'created_at'?: string;
    /**
     * Last update flow date (Format ISO 8601)
     * @type {string}
     * @memberof Flow
     */
    'updated_at'?: string;
}

export const FlowTypeOffersEnum = {
    Neuf: 'NEUF',
    Ancien: 'ANCIEN'
} as const;

export type FlowTypeOffersEnum = typeof FlowTypeOffersEnum[keyof typeof FlowTypeOffersEnum];
export const FlowFormatEnum = {
    PolirisCsv: 'POLIRIS_CSV',
    ApiXml: 'API_XML',
    ApiJson: 'API_JSON'
} as const;

export type FlowFormatEnum = typeof FlowFormatEnum[keyof typeof FlowFormatEnum];
export const FlowStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type FlowStatusEnum = typeof FlowStatusEnum[keyof typeof FlowStatusEnum];

/**
 * 
 * @export
 * @interface FlowAllOf
 */
export interface FlowAllOf {
    /**
     * Unique id (format UUID) of the flow
     * @type {string}
     * @memberof FlowAllOf
     */
    'ID'?: string;
    /**
     * Created flow date (Format ISO 8601)
     * @type {string}
     * @memberof FlowAllOf
     */
    'created_at'?: string;
    /**
     * Last update flow date (Format ISO 8601)
     * @type {string}
     * @memberof FlowAllOf
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface FlowJobs
 */
export interface FlowJobs {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof FlowJobs
     */
    'count'?: number;
    /**
     * Apikeys fetched
     * @type {Array<Job>}
     * @memberof FlowJobs
     */
    'flow_jobs'?: Array<Job>;
}
/**
 * 
 * @export
 * @interface FlowsStatistics
 */
export interface FlowsStatistics {
    /**
     * number of active property
     * @type {number}
     * @memberof FlowsStatistics
     */
    'nb_ads': number;
    /**
     * number of active flows
     * @type {number}
     * @memberof FlowsStatistics
     */
    'nb_flows': number;
    /**
     * number of total flows
     * @type {number}
     * @memberof FlowsStatistics
     */
    'nb_flows_total'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowsStatistics
     */
    'jobs_ok': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowsStatistics
     */
    'jobs_error': Array<string>;
}
/**
 * 
 * @export
 * @interface InvoiceAccount
 */
export interface InvoiceAccount {
    /**
     * Name of the invoice account
     * @type {string}
     * @memberof InvoiceAccount
     */
    'name': string;
    /**
     * Id of the company linked with the invoice account
     * @type {string}
     * @memberof InvoiceAccount
     */
    'company_ID': string;
    /**
     * Apikeys
     * @type {Array<Apikey>}
     * @memberof InvoiceAccount
     */
    'apikeys'?: Array<Apikey>;
    /**
     * Subscriptions
     * @type {Array<Subscription>}
     * @memberof InvoiceAccount
     */
    'subscriptions'?: Array<Subscription>;
    /**
     * Unique id (format UUID) of the invoice account
     * @type {string}
     * @memberof InvoiceAccount
     */
    'ID'?: string;
    /**
     * Created user date (Format ISO 8601)
     * @type {string}
     * @memberof InvoiceAccount
     */
    'created_at'?: string;
    /**
     * Last update user date (Format ISO 8601)
     * @type {string}
     * @memberof InvoiceAccount
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface InvoiceAccountAllOf
 */
export interface InvoiceAccountAllOf {
    /**
     * Apikeys
     * @type {Array<Apikey>}
     * @memberof InvoiceAccountAllOf
     */
    'apikeys'?: Array<Apikey>;
    /**
     * Subscriptions
     * @type {Array<Subscription>}
     * @memberof InvoiceAccountAllOf
     */
    'subscriptions'?: Array<Subscription>;
    /**
     * Unique id (format UUID) of the invoice account
     * @type {string}
     * @memberof InvoiceAccountAllOf
     */
    'ID'?: string;
    /**
     * Created user date (Format ISO 8601)
     * @type {string}
     * @memberof InvoiceAccountAllOf
     */
    'created_at'?: string;
    /**
     * Last update user date (Format ISO 8601)
     * @type {string}
     * @memberof InvoiceAccountAllOf
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * Job pid
     * @type {string}
     * @memberof Job
     */
    'pid': string;
    /**
     * Job name (same as flow code)
     * @type {string}
     * @memberof Job
     */
    'job'?: string;
    /**
     * Job run context
     * @type {string}
     * @memberof Job
     */
    'context': string;
    /**
     * Job run status
     * @type {string}
     * @memberof Job
     */
    'message'?: string;
    /**
     * Job run date
     * @type {string}
     * @memberof Job
     */
    'moment'?: string;
    /**
     * Job run duration
     * @type {number}
     * @memberof Job
     */
    'duration': number;
    /**
     * 
     * @type {JobError}
     * @memberof Job
     */
    'error'?: JobError;
}
/**
 * 
 * @export
 * @interface JobError
 */
export interface JobError {
    /**
     * Job pid
     * @type {string}
     * @memberof JobError
     */
    'pid': string;
    /**
     * Error type
     * @type {string}
     * @memberof JobError
     */
    'type': string;
    /**
     * Error component origin
     * @type {string}
     * @memberof JobError
     */
    'origin': string;
    /**
     * Error message
     * @type {string}
     * @memberof JobError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Media
     */
    'id'?: number;
    /**
     * position preference to show
     * @type {number}
     * @memberof Media
     */
    'position'?: number;
    /**
     * URL of media
     * @type {string}
     * @memberof Media
     */
    'url'?: string;
    /**
     * Path of media
     * @type {string}
     * @memberof Media
     */
    'path'?: string;
    /**
     * Folder of media
     * @type {string}
     * @memberof Media
     */
    'folder'?: string;
    /**
     * Type of media
     * @type {string}
     * @memberof Media
     */
    'type'?: string;
}
/**
 * A representation of an errorMessage
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * error Message
     * @type {string}
     * @memberof ModelError
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface NewApikey
 */
export interface NewApikey {
    /**
     * Name of the ApiKey
     * @type {string}
     * @memberof NewApikey
     */
    'name': string;
    /**
     * Validity date until the apikey is enabled (Format ISO 8601)
     * @type {string}
     * @memberof NewApikey
     */
    'validity_date'?: string;
    /**
     * Id of the subscription linked with the ApiKey
     * @type {string}
     * @memberof NewApikey
     */
    'subscription_ID': string;
    /**
     * White list for authorized domains or IPs (separated by \";\")
     * @type {string}
     * @memberof NewApikey
     */
    'white_list'?: string;
}
/**
 * 
 * @export
 * @interface NewCompany
 */
export interface NewCompany {
    /**
     * Name of the company
     * @type {string}
     * @memberof NewCompany
     */
    'name': string;
    /**
     * Code of the company
     * @type {string}
     * @memberof NewCompany
     */
    'code': string;
    /**
     * Description of the company
     * @type {string}
     * @memberof NewCompany
     */
    'description': string;
    /**
     * Contact email of the company
     * @type {string}
     * @memberof NewCompany
     */
    'email': string;
    /**
     * First field Address of the company
     * @type {string}
     * @memberof NewCompany
     */
    'address_1': string;
    /**
     * Second field Address of the company
     * @type {string}
     * @memberof NewCompany
     */
    'address_2'?: string;
    /**
     * Address Zip Code of the company
     * @type {string}
     * @memberof NewCompany
     */
    'zip_code': string;
    /**
     * Country of the company
     * @type {string}
     * @memberof NewCompany
     */
    'country': string;
    /**
     * Legal contact of the company
     * @type {string}
     * @memberof NewCompany
     */
    'contact_legal': string;
    /**
     * Legal contact of the company
     * @type {string}
     * @memberof NewCompany
     */
    'registration_number': string;
    /**
     * Contact url of the company
     * @type {string}
     * @memberof NewCompany
     */
    'URL': string;
    /**
     * Link to the logo of the company
     * @type {string}
     * @memberof NewCompany
     */
    'logo': string;
    /**
     * VAT of the comapny
     * @type {string}
     * @memberof NewCompany
     */
    'vat'?: string;
    /**
     * Type of the company
     * @type {string}
     * @memberof NewCompany
     */
    'business_domain': NewCompanyBusinessDomainEnum;
    /**
     * Status of the company
     * @type {string}
     * @memberof NewCompany
     */
    'status': NewCompanyStatusEnum;
}

export const NewCompanyBusinessDomainEnum = {
    None: 'NONE',
    Bank: 'BANK',
    Insurance: 'INSURANCE'
} as const;

export type NewCompanyBusinessDomainEnum = typeof NewCompanyBusinessDomainEnum[keyof typeof NewCompanyBusinessDomainEnum];
export const NewCompanyStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type NewCompanyStatusEnum = typeof NewCompanyStatusEnum[keyof typeof NewCompanyStatusEnum];

/**
 * 
 * @export
 * @interface NewContact
 */
export interface NewContact {
    /**
     * Firstname of the contact
     * @type {string}
     * @memberof NewContact
     */
    'first_name': string;
    /**
     * LastName of the contact
     * @type {string}
     * @memberof NewContact
     */
    'last_name': string;
    /**
     * Email of the contact
     * @type {string}
     * @memberof NewContact
     */
    'email'?: string;
    /**
     * Mobile of the contact
     * @type {string}
     * @memberof NewContact
     */
    'mobile'?: string;
    /**
     * Id of the project linked with the contact (required if flow_id null)
     * @type {string}
     * @memberof NewContact
     */
    'project_ID'?: string;
    /**
     * Id of the flow linked with the contact (required if project_ID null)
     * @type {string}
     * @memberof NewContact
     */
    'flow_ID'?: string;
    /**
     * Gender of the contact
     * @type {string}
     * @memberof NewContact
     */
    'gender': NewContactGenderEnum;
    /**
     * Type of the contact
     * @type {string}
     * @memberof NewContact
     */
    'job'?: string;
}

export const NewContactGenderEnum = {
    Other: 'OTHER',
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type NewContactGenderEnum = typeof NewContactGenderEnum[keyof typeof NewContactGenderEnum];

/**
 * 
 * @export
 * @interface NewFlow
 */
export interface NewFlow {
    /**
     * Name of the flow
     * @type {string}
     * @memberof NewFlow
     */
    'name': string;
    /**
     * Description of the flow
     * @type {string}
     * @memberof NewFlow
     */
    'description': string;
    /**
     * Link to the logo of the flow provider
     * @type {string}
     * @memberof NewFlow
     */
    'logo': string;
    /**
     * Code origin of the flow
     * @type {string}
     * @memberof NewFlow
     */
    'flow_origin': string;
    /**
     * Job name of the flow
     * @type {string}
     * @memberof NewFlow
     */
    'job_name': string;
    /**
     * Type of the offers in the flow
     * @type {string}
     * @memberof NewFlow
     */
    'type_offers': NewFlowTypeOffersEnum;
    /**
     * Format of the data flow
     * @type {string}
     * @memberof NewFlow
     */
    'format': NewFlowFormatEnum;
    /**
     * Status of the flow
     * @type {string}
     * @memberof NewFlow
     */
    'status': NewFlowStatusEnum;
}

export const NewFlowTypeOffersEnum = {
    Neuf: 'NEUF',
    Ancien: 'ANCIEN'
} as const;

export type NewFlowTypeOffersEnum = typeof NewFlowTypeOffersEnum[keyof typeof NewFlowTypeOffersEnum];
export const NewFlowFormatEnum = {
    PolirisCsv: 'POLIRIS_CSV',
    ApiXml: 'API_XML',
    ApiJson: 'API_JSON'
} as const;

export type NewFlowFormatEnum = typeof NewFlowFormatEnum[keyof typeof NewFlowFormatEnum];
export const NewFlowStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type NewFlowStatusEnum = typeof NewFlowStatusEnum[keyof typeof NewFlowStatusEnum];

/**
 * 
 * @export
 * @interface NewInvoiceAccount
 */
export interface NewInvoiceAccount {
    /**
     * Name of the invoice account
     * @type {string}
     * @memberof NewInvoiceAccount
     */
    'name': string;
    /**
     * Id of the company linked with the invoice account
     * @type {string}
     * @memberof NewInvoiceAccount
     */
    'company_ID': string;
}
/**
 * 
 * @export
 * @interface NewMail
 */
export interface NewMail {
    /**
     * Contact First Name
     * @type {string}
     * @memberof NewMail
     */
    'first_name': string;
    /**
     * Contact Last Name
     * @type {string}
     * @memberof NewMail
     */
    'last_name': string;
    /**
     * Contact mail
     * @type {string}
     * @memberof NewMail
     */
    'from': string;
    /**
     * Contact tel
     * @type {string}
     * @memberof NewMail
     */
    'tel': string;
    /**
     * Contact mail content
     * @type {string}
     * @memberof NewMail
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface NewProduct
 */
export interface NewProduct {
    /**
     * Name of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'name': string;
    /**
     * Technical code
     * @type {string}
     * @memberof NewProduct
     */
    'code'?: string;
    /**
     * Describe the Product
     * @type {string}
     * @memberof NewProduct
     */
    'description': string;
    /**
     * Link details of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'details_link'?: string;
    /**
     * Link documentation of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'documentation_link'?: string;
    /**
     * Version of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'version': string;
    /**
     * Url of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'URL'?: string;
    /**
     * Link to the banner of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'banner': string;
    /**
     * Link to the logo of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'logo': string;
    /**
     * Category of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'category': NewProductCategoryEnum;
    /**
     * Status of the Product
     * @type {string}
     * @memberof NewProduct
     */
    'status': NewProductStatusEnum;
}

export const NewProductCategoryEnum = {
    Api: 'API',
    Saas: 'SAAS'
} as const;

export type NewProductCategoryEnum = typeof NewProductCategoryEnum[keyof typeof NewProductCategoryEnum];
export const NewProductStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type NewProductStatusEnum = typeof NewProductStatusEnum[keyof typeof NewProductStatusEnum];

/**
 * 
 * @export
 * @interface NewProject
 */
export interface NewProject {
    /**
     * Name of the project
     * @type {string}
     * @memberof NewProject
     */
    'name': string;
    /**
     * Description of the project
     * @type {string}
     * @memberof NewProject
     */
    'description': string;
    /**
     * Version of the Project
     * @type {string}
     * @memberof NewProject
     */
    'version'?: string;
    /**
     * Url of the Project
     * @type {string}
     * @memberof NewProject
     */
    'URL'?: string;
    /**
     * Id of the company linked with the invoice account
     * @type {string}
     * @memberof NewProject
     */
    'company_ID': string;
}
/**
 * 
 * @export
 * @interface NewSubscription
 */
export interface NewSubscription {
    /**
     * Id of the invoice account linked with the Subscription
     * @type {string}
     * @memberof NewSubscription
     */
    'invoice_account_ID': string;
    /**
     * Id of the product linked with the Subscription
     * @type {string}
     * @memberof NewSubscription
     */
    'product_ID': string;
    /**
     * Describe the subscription
     * @type {string}
     * @memberof NewSubscription
     */
    'description'?: string;
    /**
     * Start Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof NewSubscription
     */
    'start_date': string;
    /**
     * Duration in number of days
     * @type {number}
     * @memberof NewSubscription
     */
    'duration_days': number;
    /**
     * Type of the subscription
     * @type {string}
     * @memberof NewSubscription
     */
    'type': NewSubscriptionTypeEnum;
    /**
     * Status of the subscription
     * @type {string}
     * @memberof NewSubscription
     */
    'status': NewSubscriptionStatusEnum;
}

export const NewSubscriptionTypeEnum = {
    Development: 'DEVELOPMENT',
    Production: 'PRODUCTION'
} as const;

export type NewSubscriptionTypeEnum = typeof NewSubscriptionTypeEnum[keyof typeof NewSubscriptionTypeEnum];
export const NewSubscriptionStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type NewSubscriptionStatusEnum = typeof NewSubscriptionStatusEnum[keyof typeof NewSubscriptionStatusEnum];

/**
 * 
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * Firstname of the user
     * @type {string}
     * @memberof NewUser
     */
    'first_name': string;
    /**
     * LastName of the user
     * @type {string}
     * @memberof NewUser
     */
    'last_name': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof NewUser
     */
    'email': string;
    /**
     * Mobile of the user
     * @type {string}
     * @memberof NewUser
     */
    'mobile'?: string;
    /**
     * Id of the company linked with the user
     * @type {string}
     * @memberof NewUser
     */
    'company_ID': string;
    /**
     * Gender of the user
     * @type {string}
     * @memberof NewUser
     */
    'gender': NewUserGenderEnum;
    /**
     * Type of the user
     * @type {string}
     * @memberof NewUser
     */
    'job_role': NewUserJobRoleEnum;
    /**
     * Role of the user
     * @type {string}
     * @memberof NewUser
     */
    'role': NewUserRoleEnum;
}

export const NewUserGenderEnum = {
    Other: 'OTHER',
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type NewUserGenderEnum = typeof NewUserGenderEnum[keyof typeof NewUserGenderEnum];
export const NewUserJobRoleEnum = {
    Technical: 'TECHNICAL',
    Business: 'BUSINESS',
    Marketing: 'MARKETING'
} as const;

export type NewUserJobRoleEnum = typeof NewUserJobRoleEnum[keyof typeof NewUserJobRoleEnum];
export const NewUserRoleEnum = {
    AdminBo: 'ADMIN_BO',
    BusinessBo: 'BUSINESS_BO',
    AdminCustomer: 'ADMIN_CUSTOMER',
    BusinessCustomer: 'BUSINESS_CUSTOMER'
} as const;

export type NewUserRoleEnum = typeof NewUserRoleEnum[keyof typeof NewUserRoleEnum];

/**
 * 
 * @export
 * @interface PartialCompany
 */
export interface PartialCompany {
    /**
     * Name of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'name': string;
    /**
     * Description of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'description': string;
    /**
     * Contact url of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'URL': string;
    /**
     * Legal contact of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'registration_number'?: string;
    /**
     * VAT of the comapny
     * @type {string}
     * @memberof PartialCompany
     */
    'vat'?: string;
    /**
     * Legal contact of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'contact_legal': string;
    /**
     * Contact email of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'email': string;
    /**
     * First field Address of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'address_1': string;
    /**
     * Second field Address of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'address_2'?: string;
    /**
     * Address Zip Code of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'zip_code': string;
    /**
     * Country of the company
     * @type {string}
     * @memberof PartialCompany
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface PartialUser
 */
export interface PartialUser {
    /**
     * Firstname of the user
     * @type {string}
     * @memberof PartialUser
     */
    'first_name': string;
    /**
     * LastName of the user
     * @type {string}
     * @memberof PartialUser
     */
    'last_name': string;
    /**
     * Gender of the user
     * @type {string}
     * @memberof PartialUser
     */
    'gender': PartialUserGenderEnum;
    /**
     * Mobile of the user
     * @type {string}
     * @memberof PartialUser
     */
    'mobile'?: string;
}

export const PartialUserGenderEnum = {
    Other: 'OTHER',
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type PartialUserGenderEnum = typeof PartialUserGenderEnum[keyof typeof PartialUserGenderEnum];

/**
 * Product subscribed in the Subscription
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Name of the Product
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * Technical code
     * @type {string}
     * @memberof Product
     */
    'code'?: string;
    /**
     * Describe the Product
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     * Link details of the Product
     * @type {string}
     * @memberof Product
     */
    'details_link'?: string;
    /**
     * Link documentation of the Product
     * @type {string}
     * @memberof Product
     */
    'documentation_link'?: string;
    /**
     * Version of the Product
     * @type {string}
     * @memberof Product
     */
    'version': string;
    /**
     * Url of the Product
     * @type {string}
     * @memberof Product
     */
    'URL'?: string;
    /**
     * Link to the banner of the Product
     * @type {string}
     * @memberof Product
     */
    'banner': string;
    /**
     * Link to the logo of the Product
     * @type {string}
     * @memberof Product
     */
    'logo': string;
    /**
     * Category of the Product
     * @type {string}
     * @memberof Product
     */
    'category': ProductCategoryEnum;
    /**
     * Status of the Product
     * @type {string}
     * @memberof Product
     */
    'status': ProductStatusEnum;
    /**
     * Unique id (format UUID) of the Product
     * @type {string}
     * @memberof Product
     */
    'ID'?: string;
    /**
     * Created Product date (Format ISO 8601)
     * @type {string}
     * @memberof Product
     */
    'created_at'?: string;
    /**
     * Last update Product date (Format ISO 8601)
     * @type {string}
     * @memberof Product
     */
    'updated_at'?: string;
}

export const ProductCategoryEnum = {
    Api: 'API',
    Saas: 'SAAS'
} as const;

export type ProductCategoryEnum = typeof ProductCategoryEnum[keyof typeof ProductCategoryEnum];
export const ProductStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];

/**
 * 
 * @export
 * @interface ProductAllOf
 */
export interface ProductAllOf {
    /**
     * Unique id (format UUID) of the Product
     * @type {string}
     * @memberof ProductAllOf
     */
    'ID'?: string;
    /**
     * Created Product date (Format ISO 8601)
     * @type {string}
     * @memberof ProductAllOf
     */
    'created_at'?: string;
    /**
     * Last update Product date (Format ISO 8601)
     * @type {string}
     * @memberof ProductAllOf
     */
    'updated_at'?: string;
}
/**
 * Project
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * Name of the project
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * Description of the project
     * @type {string}
     * @memberof Project
     */
    'description': string;
    /**
     * Version of the Project
     * @type {string}
     * @memberof Project
     */
    'version'?: string;
    /**
     * Url of the Project
     * @type {string}
     * @memberof Project
     */
    'URL'?: string;
    /**
     * Id of the company linked with the invoice account
     * @type {string}
     * @memberof Project
     */
    'company_ID': string;
    /**
     * Unique id (format UUID) of the Project
     * @type {string}
     * @memberof Project
     */
    'ID'?: string;
    /**
     * Created date (Format ISO 8601)
     * @type {string}
     * @memberof Project
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Project
     */
    'updated_at'?: string;
    /**
     * Contacts
     * @type {Array<Contact>}
     * @memberof Project
     */
    'contacts'?: Array<Contact>;
}
/**
 * 
 * @export
 * @interface ProjectAllOf
 */
export interface ProjectAllOf {
    /**
     * Unique id (format UUID) of the Project
     * @type {string}
     * @memberof ProjectAllOf
     */
    'ID'?: string;
    /**
     * Created date (Format ISO 8601)
     * @type {string}
     * @memberof ProjectAllOf
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof ProjectAllOf
     */
    'updated_at'?: string;
    /**
     * Contacts
     * @type {Array<Contact>}
     * @memberof ProjectAllOf
     */
    'contacts'?: Array<Contact>;
}
/**
 * 
 * @export
 * @interface ResponseFetchAds
 */
export interface ResponseFetchAds {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchAds
     */
    'count'?: number;
    /**
     * Ads fetched
     * @type {Array<Ad>}
     * @memberof ResponseFetchAds
     */
    'ads'?: Array<Ad>;
}
/**
 * 
 * @export
 * @interface ResponseFetchAdsPrograms
 */
export interface ResponseFetchAdsPrograms {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchAdsPrograms
     */
    'count'?: number;
    /**
     * Ads programs fetched
     * @type {Array<AdProgram>}
     * @memberof ResponseFetchAdsPrograms
     */
    'ads_programs'?: Array<AdProgram>;
}
/**
 * 
 * @export
 * @interface ResponseFetchAdsProgramsLots
 */
export interface ResponseFetchAdsProgramsLots {
    /**
     * Ads programs lots fetched
     * @type {Array<AdProgramLot>}
     * @memberof ResponseFetchAdsProgramsLots
     */
    'ads_programs_lots'?: Array<AdProgramLot>;
}
/**
 * 
 * @export
 * @interface ResponseFetchApikeys
 */
export interface ResponseFetchApikeys {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchApikeys
     */
    'count'?: number;
    /**
     * Apikeys fetched
     * @type {Array<Apikey>}
     * @memberof ResponseFetchApikeys
     */
    'apikeys'?: Array<Apikey>;
}
/**
 * 
 * @export
 * @interface ResponseFetchCompanies
 */
export interface ResponseFetchCompanies {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchCompanies
     */
    'count'?: number;
    /**
     * Companies
     * @type {Array<Company>}
     * @memberof ResponseFetchCompanies
     */
    'companies'?: Array<Company>;
}
/**
 * 
 * @export
 * @interface ResponseFetchContacts
 */
export interface ResponseFetchContacts {
    /**
     * number of contacts in the response
     * @type {number}
     * @memberof ResponseFetchContacts
     */
    'count'?: number;
    /**
     * Invoice contacts fetched
     * @type {Array<Contact>}
     * @memberof ResponseFetchContacts
     */
    'contacts'?: Array<Contact>;
}
/**
 * 
 * @export
 * @interface ResponseFetchFlows
 */
export interface ResponseFetchFlows {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchFlows
     */
    'count'?: number;
    /**
     * Flows fetched
     * @type {Array<Flow>}
     * @memberof ResponseFetchFlows
     */
    'flows'?: Array<Flow>;
}
/**
 * 
 * @export
 * @interface ResponseFetchFlowsOrigins
 */
export interface ResponseFetchFlowsOrigins {
    /**
     * Flows origins fetched
     * @type {Array<string>}
     * @memberof ResponseFetchFlowsOrigins
     */
    'flows_origins'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResponseFetchInvoiceAccounts
 */
export interface ResponseFetchInvoiceAccounts {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchInvoiceAccounts
     */
    'count'?: number;
    /**
     * Invoice accounts fetched
     * @type {Array<InvoiceAccount>}
     * @memberof ResponseFetchInvoiceAccounts
     */
    'invoice_accounts'?: Array<InvoiceAccount>;
}
/**
 * 
 * @export
 * @interface ResponseFetchProjects
 */
export interface ResponseFetchProjects {
    /**
     * number of projects in the response
     * @type {number}
     * @memberof ResponseFetchProjects
     */
    'count'?: number;
    /**
     * Projects fetched
     * @type {Array<Project>}
     * @memberof ResponseFetchProjects
     */
    'projects'?: Array<Project>;
}
/**
 * 
 * @export
 * @interface ResponseFetchUsers
 */
export interface ResponseFetchUsers {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseFetchUsers
     */
    'count'?: number;
    /**
     * Users fetched
     * @type {Array<User>}
     * @memberof ResponseFetchUsers
     */
    'users'?: Array<User>;
}
/**
 * 
 * @export
 * @interface ResponseGetGoStats
 */
export interface ResponseGetGoStats {
    /**
     * unix timestamp as nano-seconds
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'time'?: number;
    /**
     * runtime.Version()
     * @type {string}
     * @memberof ResponseGetGoStats
     */
    'go_version'?: string;
    /**
     * runtime.GOOS
     * @type {string}
     * @memberof ResponseGetGoStats
     */
    'go_os'?: string;
    /**
     * runtime.GOARCH
     * @type {string}
     * @memberof ResponseGetGoStats
     */
    'go_arch'?: string;
    /**
     * number of CPUs
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'cpu_num'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'goroutine_num'?: number;
    /**
     * runtime.GOMAXRPOCS(0)
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'gomaxprocs'?: number;
    /**
     * runtime.NumCgoCall()
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'cgo_call_num'?: number;
    /**
     * bytes allocated and not yet freed
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_alloc'?: number;
    /**
     * bytes allocated (even if freed)
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_total_alloc'?: number;
    /**
     * bytes obtained from system
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_sys'?: number;
    /**
     * number of pointer lookups
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_lookups'?: number;
    /**
     * number of mallocs
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_mallocs'?: number;
    /**
     * number of frees
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_frees'?: number;
    /**
     * bytes used by stack allocator
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'memory_stack'?: number;
    /**
     * bytes allocated and not yet freed (same as memory_alloc above)
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'heap_alloc'?: number;
    /**
     * bytes obtained from system (not same as memory_sys)
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'heap_sys'?: number;
    /**
     * bytes in idle spans
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'heap_idle'?: number;
    /**
     * bytes in non-idle span
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'heap_inuse'?: number;
    /**
     * bytes released to the OS
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'heap_released'?: number;
    /**
     * total number of allocated objects
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'heap_objects'?: number;
    /**
     * next collection will happen when HeapAlloc ≥ this amount
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'gc_next'?: number;
    /**
     * end time of last collection
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'gc_last'?: number;
    /**
     * number of GC-run
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'gc_num'?: number;
    /**
     * number of GC-run per second
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'gc_per_second'?: number;
    /**
     * pause duration by GC per seconds
     * @type {number}
     * @memberof ResponseGetGoStats
     */
    'gc_pause_per_second'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ResponseGetGoStats
     */
    'gc_pause'?: Array<number>;
}
/**
 * 
 * @export
 * @interface StatusIn
 */
export interface StatusIn {
    /**
     * Id of the subscription
     * @type {string}
     * @memberof StatusIn
     */
    'subscription_ID': string;
    /**
     * 
     * @type {boolean}
     * @memberof StatusIn
     */
    'enable'?: boolean;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * Id of the invoice account linked with the Subscription
     * @type {string}
     * @memberof Subscription
     */
    'invoice_account_ID': string;
    /**
     * Id of the product linked with the Subscription
     * @type {string}
     * @memberof Subscription
     */
    'product_ID': string;
    /**
     * Describe the subscription
     * @type {string}
     * @memberof Subscription
     */
    'description'?: string;
    /**
     * Start Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof Subscription
     */
    'start_date': string;
    /**
     * Duration in number of days
     * @type {number}
     * @memberof Subscription
     */
    'duration_days': number;
    /**
     * Type of the subscription
     * @type {string}
     * @memberof Subscription
     */
    'type': SubscriptionTypeEnum;
    /**
     * Status of the subscription
     * @type {string}
     * @memberof Subscription
     */
    'status': SubscriptionStatusEnum;
    /**
     * Unique id (format UUID) of the Subscription
     * @type {string}
     * @memberof Subscription
     */
    'ID'?: string;
    /**
     * Created Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof Subscription
     */
    'created_at'?: string;
    /**
     * Last update Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof Subscription
     */
    'updated_at'?: string;
}

export const SubscriptionTypeEnum = {
    Development: 'DEVELOPMENT',
    Production: 'PRODUCTION'
} as const;

export type SubscriptionTypeEnum = typeof SubscriptionTypeEnum[keyof typeof SubscriptionTypeEnum];
export const SubscriptionStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type SubscriptionStatusEnum = typeof SubscriptionStatusEnum[keyof typeof SubscriptionStatusEnum];

/**
 * 
 * @export
 * @interface SubscriptionAllOf
 */
export interface SubscriptionAllOf {
    /**
     * Unique id (format UUID) of the Subscription
     * @type {string}
     * @memberof SubscriptionAllOf
     */
    'ID'?: string;
    /**
     * Created Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof SubscriptionAllOf
     */
    'created_at'?: string;
    /**
     * Last update Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof SubscriptionAllOf
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionExpanded
 */
export interface SubscriptionExpanded {
    /**
     * Id of the invoice account linked with the Subscription
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'invoice_account_ID': string;
    /**
     * Id of the product linked with the Subscription
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'product_ID': string;
    /**
     * Describe the subscription
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'description'?: string;
    /**
     * Start Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'start_date': string;
    /**
     * Duration in number of days
     * @type {number}
     * @memberof SubscriptionExpanded
     */
    'duration_days': number;
    /**
     * Type of the subscription
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'type': SubscriptionExpandedTypeEnum;
    /**
     * Status of the subscription
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'status': SubscriptionExpandedStatusEnum;
    /**
     * Unique id (format UUID) of the Subscription
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'ID'?: string;
    /**
     * Created Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'created_at'?: string;
    /**
     * Last update Subscription date (Format ISO 8601)
     * @type {string}
     * @memberof SubscriptionExpanded
     */
    'updated_at'?: string;
    /**
     * 
     * @type {Product}
     * @memberof SubscriptionExpanded
     */
    'product'?: Product;
    /**
     * Number of activated keys
     * @type {number}
     * @memberof SubscriptionExpanded
     */
    'active_keys'?: number;
}

export const SubscriptionExpandedTypeEnum = {
    Development: 'DEVELOPMENT',
    Production: 'PRODUCTION'
} as const;

export type SubscriptionExpandedTypeEnum = typeof SubscriptionExpandedTypeEnum[keyof typeof SubscriptionExpandedTypeEnum];
export const SubscriptionExpandedStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Suspended: 'SUSPENDED'
} as const;

export type SubscriptionExpandedStatusEnum = typeof SubscriptionExpandedStatusEnum[keyof typeof SubscriptionExpandedStatusEnum];

/**
 * 
 * @export
 * @interface SubscriptionExpandedAllOf
 */
export interface SubscriptionExpandedAllOf {
    /**
     * 
     * @type {Product}
     * @memberof SubscriptionExpandedAllOf
     */
    'product'?: Product;
    /**
     * Number of activated keys
     * @type {number}
     * @memberof SubscriptionExpandedAllOf
     */
    'active_keys'?: number;
}
/**
 * 
 * @export
 * @interface UpdateApikey
 */
export interface UpdateApikey {
    /**
     * Name of the ApiKey
     * @type {string}
     * @memberof UpdateApikey
     */
    'name': string;
    /**
     * White list for authorized domains or IPs (separated by \";\")
     * @type {string}
     * @memberof UpdateApikey
     */
    'white_list'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * Firstname of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'first_name': string;
    /**
     * LastName of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'last_name': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'email': string;
    /**
     * Mobile of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'mobile'?: string;
    /**
     * Id of the company linked with the user
     * @type {string}
     * @memberof UpdateUser
     */
    'company_ID': string;
    /**
     * Gender of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'gender': UpdateUserGenderEnum;
    /**
     * Type of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'job_role': UpdateUserJobRoleEnum;
    /**
     * Role of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'role': UpdateUserRoleEnum;
    /**
     * Status of the user
     * @type {string}
     * @memberof UpdateUser
     */
    'status'?: UpdateUserStatusEnum;
}

export const UpdateUserGenderEnum = {
    Other: 'OTHER',
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type UpdateUserGenderEnum = typeof UpdateUserGenderEnum[keyof typeof UpdateUserGenderEnum];
export const UpdateUserJobRoleEnum = {
    Technical: 'TECHNICAL',
    Business: 'BUSINESS',
    Marketing: 'MARKETING'
} as const;

export type UpdateUserJobRoleEnum = typeof UpdateUserJobRoleEnum[keyof typeof UpdateUserJobRoleEnum];
export const UpdateUserRoleEnum = {
    AdminBo: 'ADMIN_BO',
    BusinessBo: 'BUSINESS_BO',
    AdminCustomer: 'ADMIN_CUSTOMER',
    BusinessCustomer: 'BUSINESS_CUSTOMER'
} as const;

export type UpdateUserRoleEnum = typeof UpdateUserRoleEnum[keyof typeof UpdateUserRoleEnum];
export const UpdateUserStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type UpdateUserStatusEnum = typeof UpdateUserStatusEnum[keyof typeof UpdateUserStatusEnum];

/**
 * 
 * @export
 * @interface UpdateUserAllOf
 */
export interface UpdateUserAllOf {
    /**
     * Status of the user
     * @type {string}
     * @memberof UpdateUserAllOf
     */
    'status'?: UpdateUserAllOfStatusEnum;
}

export const UpdateUserAllOfStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type UpdateUserAllOfStatusEnum = typeof UpdateUserAllOfStatusEnum[keyof typeof UpdateUserAllOfStatusEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Firstname of the user
     * @type {string}
     * @memberof User
     */
    'first_name': string;
    /**
     * LastName of the user
     * @type {string}
     * @memberof User
     */
    'last_name': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Mobile of the user
     * @type {string}
     * @memberof User
     */
    'mobile'?: string;
    /**
     * Id of the company linked with the user
     * @type {string}
     * @memberof User
     */
    'company_ID': string;
    /**
     * Gender of the user
     * @type {string}
     * @memberof User
     */
    'gender': UserGenderEnum;
    /**
     * Type of the user
     * @type {string}
     * @memberof User
     */
    'job_role': UserJobRoleEnum;
    /**
     * Role of the user
     * @type {string}
     * @memberof User
     */
    'role': UserRoleEnum;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    'company'?: Company;
    /**
     * Unique id (format UUID) of the user
     * @type {string}
     * @memberof User
     */
    'ID'?: string;
    /**
     * Unique auth provider id of the user
     * @type {string}
     * @memberof User
     */
    'provider_ID'?: string;
    /**
     * Status of the user
     * @type {string}
     * @memberof User
     */
    'status'?: UserStatusEnum;
    /**
     * Created user date (Format ISO 8601)
     * @type {string}
     * @memberof User
     */
    'created_at'?: string;
    /**
     * Last update user date (Format ISO 8601)
     * @type {string}
     * @memberof User
     */
    'updated_at'?: string;
}

export const UserGenderEnum = {
    Other: 'OTHER',
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type UserGenderEnum = typeof UserGenderEnum[keyof typeof UserGenderEnum];
export const UserJobRoleEnum = {
    Technical: 'TECHNICAL',
    Business: 'BUSINESS',
    Marketing: 'MARKETING'
} as const;

export type UserJobRoleEnum = typeof UserJobRoleEnum[keyof typeof UserJobRoleEnum];
export const UserRoleEnum = {
    AdminBo: 'ADMIN_BO',
    BusinessBo: 'BUSINESS_BO',
    AdminCustomer: 'ADMIN_CUSTOMER',
    BusinessCustomer: 'BUSINESS_CUSTOMER'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];
export const UserStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type UserStatusEnum = typeof UserStatusEnum[keyof typeof UserStatusEnum];

/**
 * 
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
    /**
     * 
     * @type {Company}
     * @memberof UserAllOf
     */
    'company'?: Company;
    /**
     * Unique id (format UUID) of the user
     * @type {string}
     * @memberof UserAllOf
     */
    'ID'?: string;
    /**
     * Unique auth provider id of the user
     * @type {string}
     * @memberof UserAllOf
     */
    'provider_ID'?: string;
    /**
     * Status of the user
     * @type {string}
     * @memberof UserAllOf
     */
    'status'?: UserAllOfStatusEnum;
    /**
     * Created user date (Format ISO 8601)
     * @type {string}
     * @memberof UserAllOf
     */
    'created_at'?: string;
    /**
     * Last update user date (Format ISO 8601)
     * @type {string}
     * @memberof UserAllOf
     */
    'updated_at'?: string;
}

export const UserAllOfStatusEnum = {
    Created: 'CREATED',
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type UserAllOfStatusEnum = typeof UserAllOfStatusEnum[keyof typeof UserAllOfStatusEnum];


/**
 * AdsApi - axios parameter creator
 * @export
 */
export const AdsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Find all ads.
         * @summary Fetch Ads
         * @param {string} [idQuery] Id like query.
         * @param {string} [zipQuery] Zip like query.
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [minIntegrationDate] Filter min integration date
         * @param {string} [maxIntegrationDate] Filter max integration date
         * @param {boolean} [enabled] is enabled
         * @param {string} [flow] flow sorting
         * @param {string} [typeParent] Type parent sorting
         * @param {string} [service] service sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAds: async (idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, typeParent?: string, service?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idQuery !== undefined) {
                localVarQueryParameter['id_query'] = idQuery;
            }

            if (zipQuery !== undefined) {
                localVarQueryParameter['zip_query'] = zipQuery;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }

            if (minIntegrationDate !== undefined) {
                localVarQueryParameter['min_integration_date'] = (minIntegrationDate as any instanceof Date) ?
                    (minIntegrationDate as any).toISOString() :
                    minIntegrationDate;
            }

            if (maxIntegrationDate !== undefined) {
                localVarQueryParameter['max_integration_date'] = (maxIntegrationDate as any instanceof Date) ?
                    (maxIntegrationDate as any).toISOString() :
                    maxIntegrationDate;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (typeParent !== undefined) {
                localVarQueryParameter['type_parent'] = typeParent;
            }

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all ads programs.
         * @summary Fetch Ads Programs
         * @param {string} [nameQuery] Name like query.
         * @param {string} [idQuery] Id like query.
         * @param {string} [zipQuery] Zip like query.
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minIntegrationDate] Filter min integration date
         * @param {string} [maxIntegrationDate] Filter max integration date
         * @param {boolean} [enabled] is enabled
         * @param {string} [flow] flow sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdsPrograms: async (nameQuery?: string, idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minDeliveryDate?: string, maxDeliveryDate?: string, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ads/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nameQuery !== undefined) {
                localVarQueryParameter['name_query'] = nameQuery;
            }

            if (idQuery !== undefined) {
                localVarQueryParameter['id_query'] = idQuery;
            }

            if (zipQuery !== undefined) {
                localVarQueryParameter['zip_query'] = zipQuery;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }

            if (minDeliveryDate !== undefined) {
                localVarQueryParameter['min_delivery_date'] = (minDeliveryDate as any instanceof Date) ?
                    (minDeliveryDate as any).toISOString() :
                    minDeliveryDate;
            }

            if (maxDeliveryDate !== undefined) {
                localVarQueryParameter['max_delivery_date'] = (maxDeliveryDate as any instanceof Date) ?
                    (maxDeliveryDate as any).toISOString() :
                    maxDeliveryDate;
            }

            if (minIntegrationDate !== undefined) {
                localVarQueryParameter['min_integration_date'] = (minIntegrationDate as any instanceof Date) ?
                    (minIntegrationDate as any).toISOString() :
                    minIntegrationDate;
            }

            if (maxIntegrationDate !== undefined) {
                localVarQueryParameter['max_integration_date'] = (maxIntegrationDate as any instanceof Date) ?
                    (maxIntegrationDate as any).toISOString() :
                    maxIntegrationDate;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all ads programs lots.
         * @summary Fetch Ads Programs Lots
         * @param {string} id ID program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdsProgramsLots: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdsProgramsLots', 'id', id)
            const localVarPath = `/ads/programs/{id}/lots`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdsApi - functional programming interface
 * @export
 */
export const AdsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdsApiAxiosParamCreator(configuration)
    return {
        /**
         * Find all ads.
         * @summary Fetch Ads
         * @param {string} [idQuery] Id like query.
         * @param {string} [zipQuery] Zip like query.
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [minIntegrationDate] Filter min integration date
         * @param {string} [maxIntegrationDate] Filter max integration date
         * @param {boolean} [enabled] is enabled
         * @param {string} [flow] flow sorting
         * @param {string} [typeParent] Type parent sorting
         * @param {string} [service] service sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAds(idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, typeParent?: string, service?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchAds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAds(idQuery, zipQuery, orderBy, pageSize, pageToken, sortDirDesc, minIntegrationDate, maxIntegrationDate, enabled, flow, typeParent, service, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all ads programs.
         * @summary Fetch Ads Programs
         * @param {string} [nameQuery] Name like query.
         * @param {string} [idQuery] Id like query.
         * @param {string} [zipQuery] Zip like query.
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minIntegrationDate] Filter min integration date
         * @param {string} [maxIntegrationDate] Filter max integration date
         * @param {boolean} [enabled] is enabled
         * @param {string} [flow] flow sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdsPrograms(nameQuery?: string, idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minDeliveryDate?: string, maxDeliveryDate?: string, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchAdsPrograms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdsPrograms(nameQuery, idQuery, zipQuery, orderBy, pageSize, pageToken, sortDirDesc, minDeliveryDate, maxDeliveryDate, minIntegrationDate, maxIntegrationDate, enabled, flow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all ads programs lots.
         * @summary Fetch Ads Programs Lots
         * @param {string} id ID program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdsProgramsLots(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchAdsProgramsLots>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdsProgramsLots(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdsApi - factory interface
 * @export
 */
export const AdsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdsApiFp(configuration)
    return {
        /**
         * Find all ads.
         * @summary Fetch Ads
         * @param {string} [idQuery] Id like query.
         * @param {string} [zipQuery] Zip like query.
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [minIntegrationDate] Filter min integration date
         * @param {string} [maxIntegrationDate] Filter max integration date
         * @param {boolean} [enabled] is enabled
         * @param {string} [flow] flow sorting
         * @param {string} [typeParent] Type parent sorting
         * @param {string} [service] service sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAds(idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, typeParent?: string, service?: string, options?: any): AxiosPromise<ResponseFetchAds> {
            return localVarFp.findAds(idQuery, zipQuery, orderBy, pageSize, pageToken, sortDirDesc, minIntegrationDate, maxIntegrationDate, enabled, flow, typeParent, service, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all ads programs.
         * @summary Fetch Ads Programs
         * @param {string} [nameQuery] Name like query.
         * @param {string} [idQuery] Id like query.
         * @param {string} [zipQuery] Zip like query.
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minIntegrationDate] Filter min integration date
         * @param {string} [maxIntegrationDate] Filter max integration date
         * @param {boolean} [enabled] is enabled
         * @param {string} [flow] flow sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdsPrograms(nameQuery?: string, idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minDeliveryDate?: string, maxDeliveryDate?: string, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, options?: any): AxiosPromise<ResponseFetchAdsPrograms> {
            return localVarFp.findAdsPrograms(nameQuery, idQuery, zipQuery, orderBy, pageSize, pageToken, sortDirDesc, minDeliveryDate, maxDeliveryDate, minIntegrationDate, maxIntegrationDate, enabled, flow, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all ads programs lots.
         * @summary Fetch Ads Programs Lots
         * @param {string} id ID program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdsProgramsLots(id: string, options?: any): AxiosPromise<ResponseFetchAdsProgramsLots> {
            return localVarFp.findAdsProgramsLots(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdsApi - object-oriented interface
 * @export
 * @class AdsApi
 * @extends {BaseAPI}
 */
export class AdsApi extends BaseAPI {
    /**
     * Find all ads.
     * @summary Fetch Ads
     * @param {string} [idQuery] Id like query.
     * @param {string} [zipQuery] Zip like query.
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {string} [minIntegrationDate] Filter min integration date
     * @param {string} [maxIntegrationDate] Filter max integration date
     * @param {boolean} [enabled] is enabled
     * @param {string} [flow] flow sorting
     * @param {string} [typeParent] Type parent sorting
     * @param {string} [service] service sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApi
     */
    public findAds(idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, typeParent?: string, service?: string, options?: AxiosRequestConfig) {
        return AdsApiFp(this.configuration).findAds(idQuery, zipQuery, orderBy, pageSize, pageToken, sortDirDesc, minIntegrationDate, maxIntegrationDate, enabled, flow, typeParent, service, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all ads programs.
     * @summary Fetch Ads Programs
     * @param {string} [nameQuery] Name like query.
     * @param {string} [idQuery] Id like query.
     * @param {string} [zipQuery] Zip like query.
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {string} [minDeliveryDate] Filter min delivery date
     * @param {string} [maxDeliveryDate] Filter max delivery date
     * @param {string} [minIntegrationDate] Filter min integration date
     * @param {string} [maxIntegrationDate] Filter max integration date
     * @param {boolean} [enabled] is enabled
     * @param {string} [flow] flow sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApi
     */
    public findAdsPrograms(nameQuery?: string, idQuery?: string, zipQuery?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, minDeliveryDate?: string, maxDeliveryDate?: string, minIntegrationDate?: string, maxIntegrationDate?: string, enabled?: boolean, flow?: string, options?: AxiosRequestConfig) {
        return AdsApiFp(this.configuration).findAdsPrograms(nameQuery, idQuery, zipQuery, orderBy, pageSize, pageToken, sortDirDesc, minDeliveryDate, maxDeliveryDate, minIntegrationDate, maxIntegrationDate, enabled, flow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all ads programs lots.
     * @summary Fetch Ads Programs Lots
     * @param {string} id ID program
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApi
     */
    public findAdsProgramsLots(id: string, options?: AxiosRequestConfig) {
        return AdsApiFp(this.configuration).findAdsProgramsLots(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiKeysApi - axios parameter creator
 * @export
 */
export const ApiKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one ApiKey in database. Duplicates are not allowed.
         * @summary Create ApiKey
         * @param {NewApikey} newApikey Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAPIKey: async (newApikey: NewApikey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newApikey' is not null or undefined
            assertParamExists('createAPIKey', 'newApikey', newApikey)
            const localVarPath = `/apikeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newApikey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one ApiKey in database.
         * @summary Update ApiKey
         * @param {string} id ID of ApiKey to update
         * @param {UpdateApikey} updateApikey Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAPIKey: async (id: string, updateApikey: UpdateApikey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAPIKey', 'id', id)
            // verify required parameter 'updateApikey' is not null or undefined
            assertParamExists('updateAPIKey', 'updateApikey', updateApikey)
            const localVarPath = `/apikeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApikey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeysApi - functional programming interface
 * @export
 */
export const ApiKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one ApiKey in database. Duplicates are not allowed.
         * @summary Create ApiKey
         * @param {NewApikey} newApikey Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAPIKey(newApikey: NewApikey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Apikey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAPIKey(newApikey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one ApiKey in database.
         * @summary Update ApiKey
         * @param {string} id ID of ApiKey to update
         * @param {UpdateApikey} updateApikey Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAPIKey(id: string, updateApikey: UpdateApikey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Apikey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAPIKey(id, updateApikey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiKeysApi - factory interface
 * @export
 */
export const ApiKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiKeysApiFp(configuration)
    return {
        /**
         * Create one ApiKey in database. Duplicates are not allowed.
         * @summary Create ApiKey
         * @param {NewApikey} newApikey Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAPIKey(newApikey: NewApikey, options?: any): AxiosPromise<Apikey> {
            return localVarFp.createAPIKey(newApikey, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one ApiKey in database.
         * @summary Update ApiKey
         * @param {string} id ID of ApiKey to update
         * @param {UpdateApikey} updateApikey Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAPIKey(id: string, updateApikey: UpdateApikey, options?: any): AxiosPromise<Apikey> {
            return localVarFp.updateAPIKey(id, updateApikey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiKeysApi - object-oriented interface
 * @export
 * @class ApiKeysApi
 * @extends {BaseAPI}
 */
export class ApiKeysApi extends BaseAPI {
    /**
     * Create one ApiKey in database. Duplicates are not allowed.
     * @summary Create ApiKey
     * @param {NewApikey} newApikey Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public createAPIKey(newApikey: NewApikey, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).createAPIKey(newApikey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one ApiKey in database.
     * @summary Update ApiKey
     * @param {string} id ID of ApiKey to update
     * @param {UpdateApikey} updateApikey Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public updateAPIKey(id: string, updateApikey: UpdateApikey, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).updateAPIKey(id, updateApikey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one company in database. Duplicates are not allowed.
         * @summary Create Company
         * @param {NewCompany} newCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (newCompany: NewCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newCompany' is not null or undefined
            assertParamExists('createCompany', 'newCompany', newCompany)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all companies.
         * @summary Fetch Companies
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCompanies: async (orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns company based on a single ID
         * @summary Returns company by ID
         * @param {string} id ID (uuid) of company to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCompanyByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findCompanyByID', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns invoice accounts based on a single Company ID
         * @summary Returns invoice accounts by Company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInvoiceAccountsByCompanyID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findInvoiceAccountsByCompanyID', 'id', id)
            const localVarPath = `/companies/{id}/invoice-accounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all projects by company ID.
         * @summary Fetch Projects by company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectsByCompanyID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectsByCompanyID', 'id', id)
            const localVarPath = `/companies/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Users based on a single Company ID
         * @summary Returns Users by Company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByCompanyID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findUsersByCompanyID', 'id', id)
            const localVarPath = `/companies/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one company in database.
         * @summary Update Company
         * @param {string} id ID (uuid) of company to fetch
         * @param {NewCompany} newCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (id: string, newCompany: NewCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompany', 'id', id)
            // verify required parameter 'newCompany' is not null or undefined
            assertParamExists('updateCompany', 'newCompany', newCompany)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one partial company in database.
         * @summary Update Partial Company
         * @param {string} id ID (uuid) of the company to update
         * @param {PartialCompany} partialCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartialCompany: async (id: string, partialCompany: PartialCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartialCompany', 'id', id)
            // verify required parameter 'partialCompany' is not null or undefined
            assertParamExists('updatePartialCompany', 'partialCompany', partialCompany)
            const localVarPath = `/companies/{id}/partial`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one company in database. Duplicates are not allowed.
         * @summary Create Company
         * @param {NewCompany} newCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(newCompany: NewCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(newCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all companies.
         * @summary Fetch Companies
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCompanies(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchCompanies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCompanies(orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns company based on a single ID
         * @summary Returns company by ID
         * @param {string} id ID (uuid) of company to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCompanyByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCompanyByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns invoice accounts based on a single Company ID
         * @summary Returns invoice accounts by Company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findInvoiceAccountsByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchInvoiceAccounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findInvoiceAccountsByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all projects by company ID.
         * @summary Fetch Projects by company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectsByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchProjects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectsByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Users based on a single Company ID
         * @summary Returns Users by Company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsersByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsersByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one company in database.
         * @summary Update Company
         * @param {string} id ID (uuid) of company to fetch
         * @param {NewCompany} newCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(id: string, newCompany: NewCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(id, newCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one partial company in database.
         * @summary Update Partial Company
         * @param {string} id ID (uuid) of the company to update
         * @param {PartialCompany} partialCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartialCompany(id: string, partialCompany: PartialCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartialCompany(id, partialCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * Create one company in database. Duplicates are not allowed.
         * @summary Create Company
         * @param {NewCompany} newCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(newCompany: NewCompany, options?: any): AxiosPromise<Company> {
            return localVarFp.createCompany(newCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all companies.
         * @summary Fetch Companies
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCompanies(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchCompanies> {
            return localVarFp.findCompanies(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns company based on a single ID
         * @summary Returns company by ID
         * @param {string} id ID (uuid) of company to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCompanyByID(id: string, options?: any): AxiosPromise<Company> {
            return localVarFp.findCompanyByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns invoice accounts based on a single Company ID
         * @summary Returns invoice accounts by Company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInvoiceAccountsByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchInvoiceAccounts> {
            return localVarFp.findInvoiceAccountsByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all projects by company ID.
         * @summary Fetch Projects by company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectsByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchProjects> {
            return localVarFp.findProjectsByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Users based on a single Company ID
         * @summary Returns Users by Company ID
         * @param {string} id ID (uuid) of company
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchUsers> {
            return localVarFp.findUsersByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one company in database.
         * @summary Update Company
         * @param {string} id ID (uuid) of company to fetch
         * @param {NewCompany} newCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(id: string, newCompany: NewCompany, options?: any): AxiosPromise<Company> {
            return localVarFp.updateCompany(id, newCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one partial company in database.
         * @summary Update Partial Company
         * @param {string} id ID (uuid) of the company to update
         * @param {PartialCompany} partialCompany Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartialCompany(id: string, partialCompany: PartialCompany, options?: any): AxiosPromise<Company> {
            return localVarFp.updatePartialCompany(id, partialCompany, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * Create one company in database. Duplicates are not allowed.
     * @summary Create Company
     * @param {NewCompany} newCompany Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public createCompany(newCompany: NewCompany, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).createCompany(newCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all companies.
     * @summary Fetch Companies
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public findCompanies(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).findCompanies(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns company based on a single ID
     * @summary Returns company by ID
     * @param {string} id ID (uuid) of company to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public findCompanyByID(id: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).findCompanyByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns invoice accounts based on a single Company ID
     * @summary Returns invoice accounts by Company ID
     * @param {string} id ID (uuid) of company
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public findInvoiceAccountsByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).findInvoiceAccountsByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all projects by company ID.
     * @summary Fetch Projects by company ID
     * @param {string} id ID (uuid) of company
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public findProjectsByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).findProjectsByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Users based on a single Company ID
     * @summary Returns Users by Company ID
     * @param {string} id ID (uuid) of company
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public findUsersByCompanyID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).findUsersByCompanyID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one company in database.
     * @summary Update Company
     * @param {string} id ID (uuid) of company to fetch
     * @param {NewCompany} newCompany Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompany(id: string, newCompany: NewCompany, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompany(id, newCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one partial company in database.
     * @summary Update Partial Company
     * @param {string} id ID (uuid) of the company to update
     * @param {PartialCompany} partialCompany Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updatePartialCompany(id: string, partialCompany: PartialCompany, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updatePartialCompany(id, partialCompany, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create contact in database. Duplicates are not allowed.
         * @summary Create contact
         * @param {NewContact} newContact Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (newContact: NewContact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newContact' is not null or undefined
            assertParamExists('createContact', 'newContact', newContact)
            const localVarPath = `/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a contact based on a single ID.
         * @summary Delete contact
         * @param {string} id ID (uuid) of contact to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContactByID', 'id', id)
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns contact based on a single ID
         * @summary Returns contact by ID
         * @param {string} id ID (uuid) of contact to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findContactByID', 'id', id)
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update contact account in database.
         * @summary Update contact
         * @param {string} id ID (uuid) of contact to update
         * @param {NewContact} newContact Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (id: string, newContact: NewContact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContact', 'id', id)
            // verify required parameter 'newContact' is not null or undefined
            assertParamExists('updateContact', 'newContact', newContact)
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create contact in database. Duplicates are not allowed.
         * @summary Create contact
         * @param {NewContact} newContact Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(newContact: NewContact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(newContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a contact based on a single ID.
         * @summary Delete contact
         * @param {string} id ID (uuid) of contact to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns contact based on a single ID
         * @summary Returns contact by ID
         * @param {string} id ID (uuid) of contact to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update contact account in database.
         * @summary Update contact
         * @param {string} id ID (uuid) of contact to update
         * @param {NewContact} newContact Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(id: string, newContact: NewContact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(id, newContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsApiFp(configuration)
    return {
        /**
         * Create contact in database. Duplicates are not allowed.
         * @summary Create contact
         * @param {NewContact} newContact Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(newContact: NewContact, options?: any): AxiosPromise<Contact> {
            return localVarFp.createContact(newContact, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a contact based on a single ID.
         * @summary Delete contact
         * @param {string} id ID (uuid) of contact to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactByID(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContactByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns contact based on a single ID
         * @summary Returns contact by ID
         * @param {string} id ID (uuid) of contact to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactByID(id: string, options?: any): AxiosPromise<Contact> {
            return localVarFp.findContactByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update contact account in database.
         * @summary Update contact
         * @param {string} id ID (uuid) of contact to update
         * @param {NewContact} newContact Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(id: string, newContact: NewContact, options?: any): AxiosPromise<Contact> {
            return localVarFp.updateContact(id, newContact, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * Create contact in database. Duplicates are not allowed.
     * @summary Create contact
     * @param {NewContact} newContact Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public createContact(newContact: NewContact, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).createContact(newContact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a contact based on a single ID.
     * @summary Delete contact
     * @param {string} id ID (uuid) of contact to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public deleteContactByID(id: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).deleteContactByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns contact based on a single ID
     * @summary Returns contact by ID
     * @param {string} id ID (uuid) of contact to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public findContactByID(id: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).findContactByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update contact account in database.
     * @summary Update contact
     * @param {string} id ID (uuid) of contact to update
     * @param {NewContact} newContact Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public updateContact(id: string, newContact: NewContact, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).updateContact(id, newContact, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlowsApi - axios parameter creator
 * @export
 */
export const FlowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one flow in database. Duplicates are not allowed.
         * @summary Create Flow
         * @param {NewFlow} newFlow Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlow: async (newFlow: NewFlow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newFlow' is not null or undefined
            assertParamExists('createFlow', 'newFlow', newFlow)
            const localVarPath = `/flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newFlow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all contact by flow ID.
         * @summary Fetch Contacts by flow ID
         * @param {string} id ID (uuid) of flow
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByFlowID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findContactsByFlowID', 'id', id)
            const localVarPath = `/flows/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns flow based on a single ID
         * @summary Returns flow by ID
         * @param {string} id ID (uuid) of flow to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findFlowByID', 'id', id)
            const localVarPath = `/flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all flow.
         * @summary Fetch Flows
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlows: async (orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all flow origins.
         * @summary Fetch Flows origins
         * @param {string} [typeOffers] Type offers sorting
         * @param {'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED'} [status] Status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowsOrigins: async (typeOffers?: string, status?: 'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flows/origins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (typeOffers !== undefined) {
                localVarQueryParameter['type_offers'] = typeOffers;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find global flows statistics.
         * @summary Fetch global Flows statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowsStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flows/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns jobs by flow job name code
         * @summary Returns jobs by flow job name code
         * @param {string} jobName Job name of flow statistics to fetch
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJobsByFlowJobName: async (jobName: string, pageSize?: number, pageToken?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobName' is not null or undefined
            assertParamExists('findJobsByFlowJobName', 'jobName', jobName)
            const localVarPath = `/flows/{job_name}/jobs`
                .replace(`{${"job_name"}}`, encodeURIComponent(String(jobName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one flow in database.
         * @summary Update Flow
         * @param {string} id ID (uuid) of flow to fetch
         * @param {NewFlow} newFlow Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlow: async (id: string, newFlow: NewFlow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFlow', 'id', id)
            // verify required parameter 'newFlow' is not null or undefined
            assertParamExists('updateFlow', 'newFlow', newFlow)
            const localVarPath = `/flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newFlow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a flow visibility based on a single ID
         * @summary Update Flow Visibility
         * @param {string} id Flow ID
         * @param {StatusIn} statusIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlowVisibility: async (id: string, statusIn: StatusIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFlowVisibility', 'id', id)
            // verify required parameter 'statusIn' is not null or undefined
            assertParamExists('updateFlowVisibility', 'statusIn', statusIn)
            const localVarPath = `/flows/{id}/visibility`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowsApi - functional programming interface
 * @export
 */
export const FlowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one flow in database. Duplicates are not allowed.
         * @summary Create Flow
         * @param {NewFlow} newFlow Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlow(newFlow: NewFlow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlow(newFlow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all contact by flow ID.
         * @summary Fetch Contacts by flow ID
         * @param {string} id ID (uuid) of flow
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactsByFlowID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchContacts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactsByFlowID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns flow based on a single ID
         * @summary Returns flow by ID
         * @param {string} id ID (uuid) of flow to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFlowByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFlowByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all flow.
         * @summary Fetch Flows
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFlows(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchFlows>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFlows(orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all flow origins.
         * @summary Fetch Flows origins
         * @param {string} [typeOffers] Type offers sorting
         * @param {'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED'} [status] Status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFlowsOrigins(typeOffers?: string, status?: 'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchFlowsOrigins>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFlowsOrigins(typeOffers, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find global flows statistics.
         * @summary Fetch global Flows statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFlowsStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowsStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFlowsStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns jobs by flow job name code
         * @summary Returns jobs by flow job name code
         * @param {string} jobName Job name of flow statistics to fetch
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findJobsByFlowJobName(jobName: string, pageSize?: number, pageToken?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowJobs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findJobsByFlowJobName(jobName, pageSize, pageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one flow in database.
         * @summary Update Flow
         * @param {string} id ID (uuid) of flow to fetch
         * @param {NewFlow} newFlow Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlow(id: string, newFlow: NewFlow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlow(id, newFlow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a flow visibility based on a single ID
         * @summary Update Flow Visibility
         * @param {string} id Flow ID
         * @param {StatusIn} statusIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlowVisibility(id: string, statusIn: StatusIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlowVisibility(id, statusIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowsApi - factory interface
 * @export
 */
export const FlowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowsApiFp(configuration)
    return {
        /**
         * Create one flow in database. Duplicates are not allowed.
         * @summary Create Flow
         * @param {NewFlow} newFlow Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlow(newFlow: NewFlow, options?: any): AxiosPromise<Flow> {
            return localVarFp.createFlow(newFlow, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all contact by flow ID.
         * @summary Fetch Contacts by flow ID
         * @param {string} id ID (uuid) of flow
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByFlowID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchContacts> {
            return localVarFp.findContactsByFlowID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns flow based on a single ID
         * @summary Returns flow by ID
         * @param {string} id ID (uuid) of flow to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowByID(id: string, options?: any): AxiosPromise<Flow> {
            return localVarFp.findFlowByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all flow.
         * @summary Fetch Flows
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlows(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchFlows> {
            return localVarFp.findFlows(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all flow origins.
         * @summary Fetch Flows origins
         * @param {string} [typeOffers] Type offers sorting
         * @param {'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED'} [status] Status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowsOrigins(typeOffers?: string, status?: 'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED', options?: any): AxiosPromise<ResponseFetchFlowsOrigins> {
            return localVarFp.findFlowsOrigins(typeOffers, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Find global flows statistics.
         * @summary Fetch global Flows statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowsStatistics(options?: any): AxiosPromise<FlowsStatistics> {
            return localVarFp.findFlowsStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns jobs by flow job name code
         * @summary Returns jobs by flow job name code
         * @param {string} jobName Job name of flow statistics to fetch
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJobsByFlowJobName(jobName: string, pageSize?: number, pageToken?: number, options?: any): AxiosPromise<FlowJobs> {
            return localVarFp.findJobsByFlowJobName(jobName, pageSize, pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one flow in database.
         * @summary Update Flow
         * @param {string} id ID (uuid) of flow to fetch
         * @param {NewFlow} newFlow Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlow(id: string, newFlow: NewFlow, options?: any): AxiosPromise<Flow> {
            return localVarFp.updateFlow(id, newFlow, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a flow visibility based on a single ID
         * @summary Update Flow Visibility
         * @param {string} id Flow ID
         * @param {StatusIn} statusIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlowVisibility(id: string, statusIn: StatusIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateFlowVisibility(id, statusIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowsApi - object-oriented interface
 * @export
 * @class FlowsApi
 * @extends {BaseAPI}
 */
export class FlowsApi extends BaseAPI {
    /**
     * Create one flow in database. Duplicates are not allowed.
     * @summary Create Flow
     * @param {NewFlow} newFlow Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public createFlow(newFlow: NewFlow, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).createFlow(newFlow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all contact by flow ID.
     * @summary Fetch Contacts by flow ID
     * @param {string} id ID (uuid) of flow
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public findContactsByFlowID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).findContactsByFlowID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns flow based on a single ID
     * @summary Returns flow by ID
     * @param {string} id ID (uuid) of flow to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public findFlowByID(id: string, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).findFlowByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all flow.
     * @summary Fetch Flows
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public findFlows(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).findFlows(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all flow origins.
     * @summary Fetch Flows origins
     * @param {string} [typeOffers] Type offers sorting
     * @param {'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED'} [status] Status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public findFlowsOrigins(typeOffers?: string, status?: 'CREATED' | 'ENABLED' | 'DISABLED' | 'SUSPENDED', options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).findFlowsOrigins(typeOffers, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find global flows statistics.
     * @summary Fetch global Flows statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public findFlowsStatistics(options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).findFlowsStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns jobs by flow job name code
     * @summary Returns jobs by flow job name code
     * @param {string} jobName Job name of flow statistics to fetch
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public findJobsByFlowJobName(jobName: string, pageSize?: number, pageToken?: number, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).findJobsByFlowJobName(jobName, pageSize, pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one flow in database.
     * @summary Update Flow
     * @param {string} id ID (uuid) of flow to fetch
     * @param {NewFlow} newFlow Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public updateFlow(id: string, newFlow: NewFlow, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).updateFlow(id, newFlow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a flow visibility based on a single ID
     * @summary Update Flow Visibility
     * @param {string} id Flow ID
     * @param {StatusIn} statusIn Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public updateFlowVisibility(id: string, statusIn: StatusIn, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).updateFlowVisibility(id, statusIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The Go stats endpoint retrieve go stats (cpu,ram, gc, go routines, ...)
         * @summary Go stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/go-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * The Go stats endpoint retrieve go stats (cpu,ram, gc, go routines, ...)
         * @summary Go stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetGoStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * The Go stats endpoint retrieve go stats (cpu,ram, gc, go routines, ...)
         * @summary Go stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoStats(options?: any): AxiosPromise<ResponseGetGoStats> {
            return localVarFp.getGoStats(options).then((request) => request(axios, basePath));
        },
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<void> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * The Go stats endpoint retrieve go stats (cpu,ram, gc, go routines, ...)
     * @summary Go stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public getGoStats(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).getGoStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The healthcheck endpoint retrieve 200 code if service is up.
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceAccountsApi - axios parameter creator
 * @export
 */
export const InvoiceAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one invoice account in database. Duplicates are not allowed.
         * @summary Create invoice account
         * @param {NewInvoiceAccount} newInvoiceAccount Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceAccount: async (newInvoiceAccount: NewInvoiceAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newInvoiceAccount' is not null or undefined
            assertParamExists('createInvoiceAccount', 'newInvoiceAccount', newInvoiceAccount)
            const localVarPath = `/invoice-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newInvoiceAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns invoice account based on a single ID
         * @summary Returns invoice account by ID
         * @param {string} id ID (uuid) of invoice account to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInvoiceAccountByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findInvoiceAccountByID', 'id', id)
            const localVarPath = `/invoice-accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Subscriptions by InvoiceAccountID.
         * @summary Fetch Subscriptions by InvoiceAccountID
         * @param {string} id ID (uuid) of InvoiceAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriptionsByInvoiceAccountID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSubscriptionsByInvoiceAccountID', 'id', id)
            const localVarPath = `/invoice-accounts/{id}/subscriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one invoice account in database.
         * @summary Update invoice account
         * @param {string} id ID (uuid) of invoice account to update
         * @param {NewInvoiceAccount} newInvoiceAccount Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceAccount: async (id: string, newInvoiceAccount: NewInvoiceAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInvoiceAccount', 'id', id)
            // verify required parameter 'newInvoiceAccount' is not null or undefined
            assertParamExists('updateInvoiceAccount', 'newInvoiceAccount', newInvoiceAccount)
            const localVarPath = `/invoice-accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newInvoiceAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceAccountsApi - functional programming interface
 * @export
 */
export const InvoiceAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one invoice account in database. Duplicates are not allowed.
         * @summary Create invoice account
         * @param {NewInvoiceAccount} newInvoiceAccount Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoiceAccount(newInvoiceAccount: NewInvoiceAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoiceAccount(newInvoiceAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns invoice account based on a single ID
         * @summary Returns invoice account by ID
         * @param {string} id ID (uuid) of invoice account to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findInvoiceAccountByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findInvoiceAccountByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Subscriptions by InvoiceAccountID.
         * @summary Fetch Subscriptions by InvoiceAccountID
         * @param {string} id ID (uuid) of InvoiceAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubscriptionsByInvoiceAccountID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionExpanded>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubscriptionsByInvoiceAccountID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one invoice account in database.
         * @summary Update invoice account
         * @param {string} id ID (uuid) of invoice account to update
         * @param {NewInvoiceAccount} newInvoiceAccount Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvoiceAccount(id: string, newInvoiceAccount: NewInvoiceAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoiceAccount(id, newInvoiceAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceAccountsApi - factory interface
 * @export
 */
export const InvoiceAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceAccountsApiFp(configuration)
    return {
        /**
         * Create one invoice account in database. Duplicates are not allowed.
         * @summary Create invoice account
         * @param {NewInvoiceAccount} newInvoiceAccount Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceAccount(newInvoiceAccount: NewInvoiceAccount, options?: any): AxiosPromise<InvoiceAccount> {
            return localVarFp.createInvoiceAccount(newInvoiceAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns invoice account based on a single ID
         * @summary Returns invoice account by ID
         * @param {string} id ID (uuid) of invoice account to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInvoiceAccountByID(id: string, options?: any): AxiosPromise<InvoiceAccount> {
            return localVarFp.findInvoiceAccountByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Subscriptions by InvoiceAccountID.
         * @summary Fetch Subscriptions by InvoiceAccountID
         * @param {string} id ID (uuid) of InvoiceAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriptionsByInvoiceAccountID(id: string, options?: any): AxiosPromise<Array<SubscriptionExpanded>> {
            return localVarFp.findSubscriptionsByInvoiceAccountID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one invoice account in database.
         * @summary Update invoice account
         * @param {string} id ID (uuid) of invoice account to update
         * @param {NewInvoiceAccount} newInvoiceAccount Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceAccount(id: string, newInvoiceAccount: NewInvoiceAccount, options?: any): AxiosPromise<InvoiceAccount> {
            return localVarFp.updateInvoiceAccount(id, newInvoiceAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceAccountsApi - object-oriented interface
 * @export
 * @class InvoiceAccountsApi
 * @extends {BaseAPI}
 */
export class InvoiceAccountsApi extends BaseAPI {
    /**
     * Create one invoice account in database. Duplicates are not allowed.
     * @summary Create invoice account
     * @param {NewInvoiceAccount} newInvoiceAccount Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAccountsApi
     */
    public createInvoiceAccount(newInvoiceAccount: NewInvoiceAccount, options?: AxiosRequestConfig) {
        return InvoiceAccountsApiFp(this.configuration).createInvoiceAccount(newInvoiceAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns invoice account based on a single ID
     * @summary Returns invoice account by ID
     * @param {string} id ID (uuid) of invoice account to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAccountsApi
     */
    public findInvoiceAccountByID(id: string, options?: AxiosRequestConfig) {
        return InvoiceAccountsApiFp(this.configuration).findInvoiceAccountByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Subscriptions by InvoiceAccountID.
     * @summary Fetch Subscriptions by InvoiceAccountID
     * @param {string} id ID (uuid) of InvoiceAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAccountsApi
     */
    public findSubscriptionsByInvoiceAccountID(id: string, options?: AxiosRequestConfig) {
        return InvoiceAccountsApiFp(this.configuration).findSubscriptionsByInvoiceAccountID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one invoice account in database.
     * @summary Update invoice account
     * @param {string} id ID (uuid) of invoice account to update
     * @param {NewInvoiceAccount} newInvoiceAccount Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAccountsApi
     */
    public updateInvoiceAccount(id: string, newInvoiceAccount: NewInvoiceAccount, options?: AxiosRequestConfig) {
        return InvoiceAccountsApiFp(this.configuration).updateInvoiceAccount(id, newInvoiceAccount, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send mail from website contact form.
         * @summary Send mail from website
         * @param {NewMail} newMail Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail: async (newMail: NewMail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newMail' is not null or undefined
            assertParamExists('sendMail', 'newMail', newMail)
            const localVarPath = `/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailApiAxiosParamCreator(configuration)
    return {
        /**
         * Send mail from website contact form.
         * @summary Send mail from website
         * @param {NewMail} newMail Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMail(newMail: NewMail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMail(newMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailApiFp(configuration)
    return {
        /**
         * Send mail from website contact form.
         * @summary Send mail from website
         * @param {NewMail} newMail Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(newMail: NewMail, options?: any): AxiosPromise<void> {
            return localVarFp.sendMail(newMail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * Send mail from website contact form.
     * @summary Send mail from website
     * @param {NewMail} newMail Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public sendMail(newMail: NewMail, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).sendMail(newMail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one Product in database. Duplicates are not allowed.
         * @summary Create Product
         * @param {NewProduct} newProduct Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (newProduct: NewProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newProduct' is not null or undefined
            assertParamExists('createProduct', 'newProduct', newProduct)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Product based on a single ID
         * @summary Returns Product by ID
         * @param {string} id ID (uuid) of Product to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProductByID', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all Products.
         * @summary Fetch Products
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProducts: async (orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one Product in database.
         * @summary Update Product
         * @param {string} id ID (uuid) of Product to fetch
         * @param {NewProduct} newProduct Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: string, newProduct: NewProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProduct', 'id', id)
            // verify required parameter 'newProduct' is not null or undefined
            assertParamExists('updateProduct', 'newProduct', newProduct)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one Product in database. Duplicates are not allowed.
         * @summary Create Product
         * @param {NewProduct} newProduct Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(newProduct: NewProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(newProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Product based on a single ID
         * @summary Returns Product by ID
         * @param {string} id ID (uuid) of Product to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProductByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all Products.
         * @summary Fetch Products
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProducts(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProducts(orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one Product in database.
         * @summary Update Product
         * @param {string} id ID (uuid) of Product to fetch
         * @param {NewProduct} newProduct Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: string, newProduct: NewProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(id, newProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * Create one Product in database. Duplicates are not allowed.
         * @summary Create Product
         * @param {NewProduct} newProduct Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(newProduct: NewProduct, options?: any): AxiosPromise<Product> {
            return localVarFp.createProduct(newProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Product based on a single ID
         * @summary Returns Product by ID
         * @param {string} id ID (uuid) of Product to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductByID(id: string, options?: any): AxiosPromise<Product> {
            return localVarFp.findProductByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all Products.
         * @summary Fetch Products
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProducts(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.findProducts(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one Product in database.
         * @summary Update Product
         * @param {string} id ID (uuid) of Product to fetch
         * @param {NewProduct} newProduct Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(id: string, newProduct: NewProduct, options?: any): AxiosPromise<Product> {
            return localVarFp.updateProduct(id, newProduct, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Create one Product in database. Duplicates are not allowed.
     * @summary Create Product
     * @param {NewProduct} newProduct Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(newProduct: NewProduct, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(newProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Product based on a single ID
     * @summary Returns Product by ID
     * @param {string} id ID (uuid) of Product to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public findProductByID(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).findProductByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all Products.
     * @summary Fetch Products
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public findProducts(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).findProducts(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one Product in database.
     * @summary Update Product
     * @param {string} id ID (uuid) of Product to fetch
     * @param {NewProduct} newProduct Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(id: string, newProduct: NewProduct, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(id, newProduct, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one project in database. Duplicates are not allowed.
         * @summary Create project
         * @param {NewProject} newProject Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (newProject: NewProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newProject' is not null or undefined
            assertParamExists('createProject', 'newProject', newProject)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a project based on a single ID and all linked contacts.
         * @summary Delete project
         * @param {string} id ID (uuid) of project to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectByID', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all contact by project ID.
         * @summary Fetch Contacts by project ID
         * @param {string} id ID (uuid) of project
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByProjectID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findContactsByProjectID', 'id', id)
            const localVarPath = `/projects/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns project based on a single ID
         * @summary Returns project by ID
         * @param {string} id ID (uuid) of project to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectByID', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update project account in database.
         * @summary Update project
         * @param {string} id ID (uuid) of project to update
         * @param {NewProject} newProject Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: string, newProject: NewProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'newProject' is not null or undefined
            assertParamExists('updateProject', 'newProject', newProject)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one project in database. Duplicates are not allowed.
         * @summary Create project
         * @param {NewProject} newProject Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(newProject: NewProject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(newProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a project based on a single ID and all linked contacts.
         * @summary Delete project
         * @param {string} id ID (uuid) of project to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all contact by project ID.
         * @summary Fetch Contacts by project ID
         * @param {string} id ID (uuid) of project
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactsByProjectID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchContacts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactsByProjectID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns project based on a single ID
         * @summary Returns project by ID
         * @param {string} id ID (uuid) of project to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update project account in database.
         * @summary Update project
         * @param {string} id ID (uuid) of project to update
         * @param {NewProject} newProject Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: string, newProject: NewProject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, newProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Create one project in database. Duplicates are not allowed.
         * @summary Create project
         * @param {NewProject} newProject Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(newProject: NewProject, options?: any): AxiosPromise<Project> {
            return localVarFp.createProject(newProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a project based on a single ID and all linked contacts.
         * @summary Delete project
         * @param {string} id ID (uuid) of project to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectByID(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all contact by project ID.
         * @summary Fetch Contacts by project ID
         * @param {string} id ID (uuid) of project
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByProjectID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchContacts> {
            return localVarFp.findContactsByProjectID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns project based on a single ID
         * @summary Returns project by ID
         * @param {string} id ID (uuid) of project to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectByID(id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.findProjectByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update project account in database.
         * @summary Update project
         * @param {string} id ID (uuid) of project to update
         * @param {NewProject} newProject Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: string, newProject: NewProject, options?: any): AxiosPromise<Project> {
            return localVarFp.updateProject(id, newProject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Create one project in database. Duplicates are not allowed.
     * @summary Create project
     * @param {NewProject} newProject Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(newProject: NewProject, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(newProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a project based on a single ID and all linked contacts.
     * @summary Delete project
     * @param {string} id ID (uuid) of project to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProjectByID(id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProjectByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all contact by project ID.
     * @summary Fetch Contacts by project ID
     * @param {string} id ID (uuid) of project
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public findContactsByProjectID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).findContactsByProjectID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns project based on a single ID
     * @summary Returns project by ID
     * @param {string} id ID (uuid) of project to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public findProjectByID(id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).findProjectByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update project account in database.
     * @summary Update project
     * @param {string} id ID (uuid) of project to update
     * @param {NewProject} newProject Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(id: string, newProject: NewProject, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(id, newProject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one Subscription in database. Duplicates are not allowed.
         * @summary Create Subscription
         * @param {NewSubscription} newSubscription Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (newSubscription: NewSubscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newSubscription' is not null or undefined
            assertParamExists('createSubscription', 'newSubscription', newSubscription)
            const localVarPath = `/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Apikeys based on a single Subscription ID
         * @summary Returns Apikeys by Subscription ID
         * @param {string} id ID (uuid) of subscription
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAPIKeysBySubscriptionID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAPIKeysBySubscriptionID', 'id', id)
            const localVarPath = `/subscriptions/{id}/apikeys`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all flow with visibility enabled by subscription ID.
         * @summary Fetch Flows visibility enabled.
         * @param {string} id ID (uuid) of the Subscription
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowsBySubscriptionID: async (id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findFlowsBySubscriptionID', 'id', id)
            const localVarPath = `/subscriptions/{id}/flows`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Subscriptions by ProductID and InvoiceAccountID.
         * @summary Fetch Subscriptions by ProductID and InvoiceAccountID
         * @param {string} productId Product ID
         * @param {string} invoiceAccountId Invoice account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriptionsByProductIDAndInvoiceAccountID: async (productId: string, invoiceAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('findSubscriptionsByProductIDAndInvoiceAccountID', 'productId', productId)
            // verify required parameter 'invoiceAccountId' is not null or undefined
            assertParamExists('findSubscriptionsByProductIDAndInvoiceAccountID', 'invoiceAccountId', invoiceAccountId)
            const localVarPath = `/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (invoiceAccountId !== undefined) {
                localVarQueryParameter['invoiceAccountId'] = invoiceAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one Subscription in database.
         * @summary Update Subscription
         * @param {string} id ID (uuid) of the Subscription to fetch
         * @param {NewSubscription} newSubscription Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (id: string, newSubscription: NewSubscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubscription', 'id', id)
            // verify required parameter 'newSubscription' is not null or undefined
            assertParamExists('updateSubscription', 'newSubscription', newSubscription)
            const localVarPath = `/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one Subscription in database. Duplicates are not allowed.
         * @summary Create Subscription
         * @param {NewSubscription} newSubscription Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(newSubscription: NewSubscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(newSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Apikeys based on a single Subscription ID
         * @summary Returns Apikeys by Subscription ID
         * @param {string} id ID (uuid) of subscription
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAPIKeysBySubscriptionID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchApikeys>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAPIKeysBySubscriptionID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all flow with visibility enabled by subscription ID.
         * @summary Fetch Flows visibility enabled.
         * @param {string} id ID (uuid) of the Subscription
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFlowsBySubscriptionID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchFlows>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFlowsBySubscriptionID(id, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Subscriptions by ProductID and InvoiceAccountID.
         * @summary Fetch Subscriptions by ProductID and InvoiceAccountID
         * @param {string} productId Product ID
         * @param {string} invoiceAccountId Invoice account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubscriptionsByProductIDAndInvoiceAccountID(productId: string, invoiceAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subscription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubscriptionsByProductIDAndInvoiceAccountID(productId, invoiceAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one Subscription in database.
         * @summary Update Subscription
         * @param {string} id ID (uuid) of the Subscription to fetch
         * @param {NewSubscription} newSubscription Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(id: string, newSubscription: NewSubscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(id, newSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * Create one Subscription in database. Duplicates are not allowed.
         * @summary Create Subscription
         * @param {NewSubscription} newSubscription Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(newSubscription: NewSubscription, options?: any): AxiosPromise<Subscription> {
            return localVarFp.createSubscription(newSubscription, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Apikeys based on a single Subscription ID
         * @summary Returns Apikeys by Subscription ID
         * @param {string} id ID (uuid) of subscription
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAPIKeysBySubscriptionID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchApikeys> {
            return localVarFp.findAPIKeysBySubscriptionID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all flow with visibility enabled by subscription ID.
         * @summary Fetch Flows visibility enabled.
         * @param {string} id ID (uuid) of the Subscription
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFlowsBySubscriptionID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchFlows> {
            return localVarFp.findFlowsBySubscriptionID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Subscriptions by ProductID and InvoiceAccountID.
         * @summary Fetch Subscriptions by ProductID and InvoiceAccountID
         * @param {string} productId Product ID
         * @param {string} invoiceAccountId Invoice account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubscriptionsByProductIDAndInvoiceAccountID(productId: string, invoiceAccountId: string, options?: any): AxiosPromise<Array<Subscription>> {
            return localVarFp.findSubscriptionsByProductIDAndInvoiceAccountID(productId, invoiceAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one Subscription in database.
         * @summary Update Subscription
         * @param {string} id ID (uuid) of the Subscription to fetch
         * @param {NewSubscription} newSubscription Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(id: string, newSubscription: NewSubscription, options?: any): AxiosPromise<Subscription> {
            return localVarFp.updateSubscription(id, newSubscription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * Create one Subscription in database. Duplicates are not allowed.
     * @summary Create Subscription
     * @param {NewSubscription} newSubscription Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public createSubscription(newSubscription: NewSubscription, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).createSubscription(newSubscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Apikeys based on a single Subscription ID
     * @summary Returns Apikeys by Subscription ID
     * @param {string} id ID (uuid) of subscription
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public findAPIKeysBySubscriptionID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).findAPIKeysBySubscriptionID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all flow with visibility enabled by subscription ID.
     * @summary Fetch Flows visibility enabled.
     * @param {string} id ID (uuid) of the Subscription
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public findFlowsBySubscriptionID(id: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).findFlowsBySubscriptionID(id, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Subscriptions by ProductID and InvoiceAccountID.
     * @summary Fetch Subscriptions by ProductID and InvoiceAccountID
     * @param {string} productId Product ID
     * @param {string} invoiceAccountId Invoice account ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public findSubscriptionsByProductIDAndInvoiceAccountID(productId: string, invoiceAccountId: string, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).findSubscriptionsByProductIDAndInvoiceAccountID(productId, invoiceAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one Subscription in database.
     * @summary Update Subscription
     * @param {string} id ID (uuid) of the Subscription to fetch
     * @param {NewSubscription} newSubscription Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public updateSubscription(id: string, newSubscription: NewSubscription, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).updateSubscription(id, newSubscription, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one user in database. Duplicates are not allowed.
         * @summary Create User
         * @param {NewUser} newUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (newUser: NewUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newUser' is not null or undefined
            assertParamExists('createUser', 'newUser', newUser)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns user based on a single provider ID
         * @summary Returns user by provider ID
         * @param {string} id ID (uuid) of the provider of user to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserByProviderID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findUserByProviderID', 'id', id)
            const localVarPath = `/users/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find all users.
         * @summary Fetch Users
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers: async (orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns user based on a single ID
         * @summary Returns user by ID
         * @param {string} id ID (uuid) of user to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one partial user in database.
         * @summary Update Partial User
         * @param {string} id ID (uuid) of user to update
         * @param {PartialUser} partialUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartialUser: async (id: string, partialUser: PartialUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartialUser', 'id', id)
            // verify required parameter 'partialUser' is not null or undefined
            assertParamExists('updatePartialUser', 'partialUser', partialUser)
            const localVarPath = `/users/{id}/partial`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one user in database.
         * @summary Update User
         * @param {string} id ID (uuid) of user to fetch
         * @param {UpdateUser} updateUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUser: UpdateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('updateUser', 'updateUser', updateUser)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one user in database. Duplicates are not allowed.
         * @summary Create User
         * @param {NewUser} newUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(newUser: NewUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(newUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns user based on a single provider ID
         * @summary Returns user by provider ID
         * @param {string} id ID (uuid) of the provider of user to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserByProviderID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserByProviderID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find all users.
         * @summary Fetch Users
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsers(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsers(orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns user based on a single ID
         * @summary Returns user by ID
         * @param {string} id ID (uuid) of user to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one partial user in database.
         * @summary Update Partial User
         * @param {string} id ID (uuid) of user to update
         * @param {PartialUser} partialUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartialUser(id: string, partialUser: PartialUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartialUser(id, partialUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one user in database.
         * @summary Update User
         * @param {string} id ID (uuid) of user to fetch
         * @param {UpdateUser} updateUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUser: UpdateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Create one user in database. Duplicates are not allowed.
         * @summary Create User
         * @param {NewUser} newUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(newUser: NewUser, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns user based on a single provider ID
         * @summary Returns user by provider ID
         * @param {string} id ID (uuid) of the provider of user to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserByProviderID(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.findUserByProviderID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Find all users.
         * @summary Fetch Users
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<ResponseFetchUsers> {
            return localVarFp.findUsers(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns user based on a single ID
         * @summary Returns user by ID
         * @param {string} id ID (uuid) of user to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one partial user in database.
         * @summary Update Partial User
         * @param {string} id ID (uuid) of user to update
         * @param {PartialUser} partialUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartialUser(id: string, partialUser: PartialUser, options?: any): AxiosPromise<User> {
            return localVarFp.updatePartialUser(id, partialUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one user in database.
         * @summary Update User
         * @param {string} id ID (uuid) of user to fetch
         * @param {UpdateUser} updateUser Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, updateUser: UpdateUser, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(id, updateUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Create one user in database. Duplicates are not allowed.
     * @summary Create User
     * @param {NewUser} newUser Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(newUser: NewUser, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns user based on a single provider ID
     * @summary Returns user by provider ID
     * @param {string} id ID (uuid) of the provider of user to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public findUserByProviderID(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).findUserByProviderID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find all users.
     * @summary Fetch Users
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public findUsers(orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).findUsers(orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns user based on a single ID
     * @summary Returns user by ID
     * @param {string} id ID (uuid) of user to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one partial user in database.
     * @summary Update Partial User
     * @param {string} id ID (uuid) of user to update
     * @param {PartialUser} partialUser Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updatePartialUser(id: string, partialUser: PartialUser, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updatePartialUser(id, partialUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one user in database.
     * @summary Update User
     * @param {string} id ID (uuid) of user to fetch
     * @param {UpdateUser} updateUser Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(id: string, updateUser: UpdateUser, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(id, updateUser, options).then((request) => request(this.axios, this.basePath));
    }
}


