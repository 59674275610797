export default [
  {
    path: "/users",
    name: "user-list",
    component: () => import("@/views/users/user-list/UserList.vue"),
    meta: {
      resource: "UserPages",
      pageTitle: "Utilisateurs",
    },
  },
  {
    path: "/users/detail/:id",
    name: "user-detail",
    component: () => import("@/views/users/user-detail/UserDetail.vue"),
    meta: {
      resource: "UserPages",
      pageTitle: "Détails",
      navActiveLink: "user-list",
    },
  },
  {
    path: "/users/edit/:id",
    name: "user-edit",
    component: () => import("@/views/users/user-edit/UserEdit.vue"),
    meta: {
      resource: "UserManagementPages",
      pageTitle: "Edition",
      navActiveLink: "user-list",
    },
  },
  {
    path: "/users/create",
    name: "user-create",
    component: () => import("@/views/users/user-create/UserCreate.vue"),
    meta: {
      resource: "UserManagementPages",
      pageTitle: "Création",
      navActiveLink: "user-list",
    },
  },
  {
    path: "/users/manage",
    name: "user-manage",
    component: () => import("@/views/users/user-manage/UserManage.vue"),
    meta: {
      resource: "UserClientManagementPages",
      pageTitle: "Gestion",
    },
  },
  {
    path: "/users/profile/:id",
    name: "user-profile",
    component: () => import("@/views/users/user-profile/UserProfile.vue"),
    meta: {
      resource: "UserClientPages",
      pageTitle: "Profil",
    },
  },
];
