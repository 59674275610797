import Vue from "vue";
import router from "../../router/index";
import * as Api from "../../axios-generated/backoffice";
import axios from "@axios";
import store from "@/store";
import { publicAbility } from "@/libs/acl/config";
import { fetchAbility } from "@/libs/acl/ability";

const getUserAPI = () => {
  return new Api.UsersApi(
    null,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL
      : "",
    axios
  );
};

const getDefaultState = () => {
  return {
    userProfile: {},
  };
};

export default {
  namespaced: true,
  state: {
    userProfile: {},
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
  },
  actions: {
    async resetModuleState({ commit }) {
      commit("resetState");
    },
    async sendPasswordResetEmail(_, form) {
      await Vue.prototype.$auth.sendPasswordResetEmail(form.email);
    },
    async logout(ctx, abilityInstance) {
      await Vue.prototype.$auth.signOut();
      // Reset ability
      if (abilityInstance) abilityInstance.update(publicAbility);
      // restore initial cdv data state in local storage
      store.dispatch("auth/resetModuleState");
      store.dispatch("invoiceAccounts/resetModuleState");
      // Redirect to login page
      router.push("/login").catch(() => {});
    },
    async fetchUserByID(ctx, { id }) {
      return new Promise((resolve, reject) => {
        getUserAPI()
          .getUser(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async findUserByProviderID({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        getUserAPI()
          .findUserByProviderID(id)
          .then((response) => {
            commit("setUserProfile", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async updatePartialUser({ commit }, { id, partialUser }) {
      return new Promise((resolve, reject) => {
        getUserAPI()
          .updatePartialUser(id, partialUser)
          .then((response) => {
            if (
              response.data.provider_ID ===
              store.state.auth.userProfile.provider_ID
            )
              commit("setUserProfile", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async initAppRole(ctx, abilityInstance) {
      return new Promise((resolve, reject) => {
        // Check token claims
        Vue.prototype.$auth.currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user has role.
            if (idTokenResult.claims) {
              abilityInstance.update(fetchAbility(idTokenResult.claims.role));
              resolve();
            } else {
              reject();
            }
          });
      });
    },
    async initAppData({ commit }) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("auth/findUserByProviderID", {
            id: Vue.prototype.$auth.currentUser.uid,
          })
          .then((response) => {
            // set user profile in state
            commit("setUserProfile", response.data);
            store
              .dispatch("invoiceAccounts/findInvoiceAccountsCurrentUser", {
                companyId: response.data.company_ID,
              })
              .then(() => router.push({ name: "board-public" }).catch(() => {}))
              .catch((err) => reject(err));
            resolve(response.data);
          })
          .catch((err) => reject(err));
      });
    },
    async login(ctx, form) {
      return new Promise((resolve, reject) => {
        // sign user in
        Vue.prototype.$auth
          .signInWithEmailAndPassword(form.email, form.password)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
