export const publicAbility = [
  {
    action: "read",
    subject: "Public",
  },
];

export const businessCustomerAbility = [
  {
    action: "read",
    subject: "ErrorPages",
  },
  {
    action: "read",
    subject: "PublicBoard",
  },
  {
    action: "read",
    subject: "RealestatePages",
  },
  {
    action: "read",
    subject: "EstimatePages",
  },
  {
    action: "read",
    subject: "SituationPages",
  },
  {
    action: "read",
    subject: "ProgramPages",
  },
  {
    action: "read",
    subject: "DecimmoPages",
  },
  {
    action: "read",
    subject: "UserClientPages",
  },
  {
    action: "read",
    subject: "Redoc",
  },
];

export const businessAdminAbility = [
  {
    action: "read",
    subject: "APIManagementPages",
  },
  {
    action: "read",
    subject: "UserClientManagementPages",
  },
];

export const boCustomerAbility = [
  {
    action: "read",
    subject: "AdsPages",
  },
  {
    action: "read",
    subject: "ProductPages",
  },
  {
    action: "read",
    subject: "FlowPages",
  },
  {
    action: "read",
    subject: "CustomerPages",
  },
  {
    action: "read",
    subject: "UserPages",
  },
  // Vertical NavBar
  {
    action: "read",
    subject: "adminLinks",
  },
];

export const boAdminAbility = [
  {
    action: "read",
    subject: "ProductManagementPages",
  },
  {
    action: "read",
    subject: "FlowManagementPages",
  },
  {
    action: "read",
    subject: "CustomerManagementPages",
  },
  {
    action: "read",
    subject: "UserManagementPages",
  },
];

export const _ = undefined;
