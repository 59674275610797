export default [
  {
    path: "/customers",
    name: "customer-list",
    component: () => import("@/views/customers/customer-list/CustomerList.vue"),
    meta: {
      resource: "CustomerPages",
      pageTitle: "Clients",
      contentClass: "itemgrid-application",
    },
  },
  {
    path: "/customers/detail/:id",
    name: "customer-detail",
    component: () =>
      import("@/views/customers/customer-detail/CustomerDetail.vue"),
    meta: {
      resource: "CustomerPages",
      pageTitle: "Détails",
      navActiveLink: "customer-list",
      contentClass: "itemgrid-application",
    },
  },
  {
    path: "/customers/edit/:id",
    name: "customer-edit",
    component: () => import("@/views/customers/customer-edit/CustomerEdit.vue"),
    meta: {
      resource: "CustomerManagementPages",
      pageTitle: "Edition",
      navActiveLink: "customer-list",
    },
  },
  {
    path: "/customers/create",
    name: "customer-create",
    component: () =>
      import("@/views/customers/customer-create/CustomerCreate.vue"),
    navActiveLink: "customer-list",
    meta: {
      resource: "CustomerManagementPages",
      pageTitle: "Création",
    },
  },
  {
    path: "/customers/invoice-account",
    name: "customer-invoice-account",
    component: () =>
      import("@/views/customers/invoice-accounts-detail/Board.vue"),
    meta: {
      resource: "CustomerManagementPages",
      contentClass: "itemgrid-application",
      navActiveLink: "customer-list",
    },
  },
];
