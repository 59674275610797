import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";

// @ts-ignore
import App from "./App.vue";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

const version = process.env.VUE_APP_VERSION;

// FORCE Refresh store if package.json version changed to force reload on new deployment.
if (!localStorage.getItem("app_version")) {
  localStorage.setItem("app_version", version);
} else if (localStorage.getItem("app_version") !== version) {
  localStorage.setItem("app_version", version);
  localStorage.removeItem("vuex");
  indexedDB.deleteDatabase("firebaseLocalStorageDb");
  window.location.reload();
}

let app = null;
let currentUser = null;

// fetch static config file for app init
fetch(`${window.location.origin}/config.json`).then((response) => {
  response.json().then((config) => {
    // firebase init - add your own config here
    const firebaseConfig = {
      apiKey: config.FIREBASE_apiKey,
      authDomain: "cadre-de-vie.firebaseapp.com",
      projectId: "cadre-de-vie",
      storageBucket: "cadre-de-vie.appspot.com",
      messagingSenderId: "1099250298910",
      appId: "1:1099250298910:web:0ff63173cb9c3500febe0f",
    };
    firebase.initializeApp(firebaseConfig);

    // utils
    const auth = firebase.auth();
    // Persiste auth state in local storage
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    // Set config for the whole app
    Vue.prototype.$config = config;
    Vue.prototype.$auth = auth;

    auth.onAuthStateChanged((user) => {
      if (!app) {
        app = new Vue({
          router,
          store,
          render: (h) => h(App),
        }).$mount("#app");
      } else {
        // *** Sync all browser windows ***
        // Catch global login signal
        if (!currentUser && user) {
          currentUser = user;
          app.$store
            .dispatch("auth/initAppRole", app.$ability)
            .then(() => app.$store.dispatch("auth/initAppData"));
        }
        // Catch global logout signal
        if (currentUser && !user) {
          currentUser = null;
          app.$store.dispatch("auth/logout", app.$ability);
        }
        // Catch global init app signal
        if (!currentUser && !user) {
          app.$store.dispatch("auth/logout", app.$ability);
        }
      }
    });
  });
});
